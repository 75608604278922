<template>
  <div>
    <div class="top flex flex-between flex-v-center">
      <div style="padding:0px 15px" class="flex flex-v-center">
        <h2>Hi {{ $store.state.mode }}fighter，</h2>
        <h4 style="margin-top:5px;">{{ $t('新的一天，自信，乐观，前行') }}！</h4>
      </div>
    </div>

    <el-image class="margin-t" v-if="$store.state.mode == 'CCL'"
      :src="$host + '/storage/banner/o07GHuCF9feyxKkybCPQJ6wbduDy3PmSGjYyWt4W.png'"></el-image>

    <el-card class="margin-t" v-if="$store.state.mode == 'PTE'">
      <div class="flex flex-v-center">
        <div class="flex flex-v-center flex-h-center flex1">
          <el-image :src="`/web/image/main/1-0${$store.state.lang}.png`"></el-image>
        </div>


        <div class="flex1 margin-x">
          <el-row :gutter="40">
            <el-col :span="6" class="center">
              <h2 class="mc">{{ items[3] }}</h2>
              <div class="mt bold">{{ $t('总题数') }}</div>
            </el-col>

            <el-col :span="6" class="center">
              <h2 style="color:red;">{{ items[4] }}</h2>
              <div class="mt bold">{{ $t('预测总数') }}</div>
            </el-col>

            <el-col :span="6" class="center">
              <h2 class="mc">{{ items[5] }}</h2>
              <div class="mt bold">{{ $t('机经总数') }}</div>
            </el-col>

            <el-col :span="6" class="center">
              <h2 class="mc">{{ items[6] }}</h2>
              <div class="mt bold">{{ $t('新题总数') }}</div>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row :gutter="40">

            <el-col :span="6" class="center">
              <h2 class="mc">{{ items[7] }}</h2>
              <div class="mt bold">{{ $t('口语预测') }}</div>
            </el-col>

            <el-col :span="6" class="center">
              <h2 class="mc">{{ items[8] }}</h2>
              <div class="mt bold">{{ $t('写作预测') }}</div>
            </el-col>


            <el-col :span="6" class="center">
              <h2 class="mc">{{ items[9] }}</h2>
              <div class="mt bold">{{ $t('阅读预测') }}</div>
            </el-col>

            <el-col :span="6" class="center">
              <h2 class="mc">{{ items[10] }}</h2>
              <div class="mt bold">{{ $t('听力预测') }}</div>
            </el-col>

            <el-col :span="24" class="margin-lt">
              <el-button @click="to('/main/pte?tag=prediction')" round type="primary"
                class="block">{{ $t('练习本周预测') }}</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-card>



    <el-card v-if="$store.state.mode == 'PTE'" class="margin-lt">
      <div class=" flex flex-h-center">
        <div class="padding">
          <div class="flex flex-between flex-v-center">
            <div class="flex flex-h-center flex-v-center" style="width: 200px;">
              <el-upload :headers="headers" accept="image/jpeg,image/jpg,image/png" :show-file-list="false"
                class="upload-demo" :action="$host + '/api/user/avatar'" :on-success="uploadSuccess" :limit="1">
                <div slot="trigger" class="avatar-box">
                  <el-image class="avatar" :src="$store.state.user.avatar ? $store.state.user.avatar.indexOf('http') == 0 ? $store.state.user.avatar : $host + $store.state.user.avatar
                    : '/web/image/logo0.png'">
                  </el-image>
                </div>
              </el-upload>
              <div class="bold margin-l">{{ $store.state.user.nickName }}</div>
            </div>
            <div class="lt bold">
              个人中心
            </div>
            <div class="flex flex-h-center flex-v-center" style="width: 200px;">
              <el-tag v-if="$store.getters.getRole1() == 4" style="margin-right:10px;" effect="dark">{{
                $t('课程VIP') }} (PTE+CCL)</el-tag>
              <el-tag v-else-if="$store.getters.getRole1() == 3" style="margin-right:10px;" effect="dark">{{
                $t('课程VIP') }} (CCL)</el-tag>
              <el-tag v-else-if="$store.getters.getRole1() == 2" style="margin-right:10px;" effect="dark">{{
                $t('课程VIP') }} (PTE)</el-tag>
              <el-tag v-if="$store.getters.getRole1() == 1" style="margin-right:10px;" effect="dark">VIP</el-tag>
              <el-image v-if="$store.state.user.certificate.status == 1" style="width: 30px;height: auto;"
                src="/web/image/pte.png"></el-image>
              <el-image v-if="$store.state.user.ccl_certificate.status == 1" style="width: 30px;height: auto;"
                src="/web/image/ccl.jpg"></el-image>
            </div>
          </div>

          <div class="center margin-ts bold">距离考试还有：{{ $store.getters.examDays() > 0 ? $store.getters.examDays() : '-'
            }} 天
          </div>

          <div class="flex flex-between flex-v-center margin-lt">
            <div style="width: 150px;">
              <el-menu>
                <el-menu-item @click="$refs.goalDialog.show()" class="border br margin mi">
                  学习目标
                </el-menu-item>
              </el-menu>

              <div class="margin-t bold center">口 语：{{ $store.state.user.exam_data ? $store.state.user.exam_data[0] : 60
                }}
              </div>
              <div class="margin-t bold center">写 作：{{ $store.state.user.exam_data ? $store.state.user.exam_data[1] : 60
                }}
              </div>
              <div class="margin-t bold center">阅 读：{{ $store.state.user.exam_data ? $store.state.user.exam_data[2] : 60
                }}
              </div>
              <div class="margin-t bold center">听 力：{{ $store.state.user.exam_data ? $store.state.user.exam_data[3] : 60
                }}
              </div>
              <div class="margin-t bold center">总 分：{{ $store.state.user.exam_data ? $store.state.user.exam_data[4] : 60
                }}
              </div>
            </div>


            <div class="margin-x flex1" style="height: 350px;display: flex;align-items: center;">
              <div class="calendar">
                <Calendar @changeMonth="monthChange" :markDateMore="markDateMore" :sundayStart="false">
                </Calendar>
              </div>
            </div>


            <div style="width: 150px;">
              <el-menu router>
                <el-menu-item index="/main/score" class="border br margin mi">
                  我的成绩
                </el-menu-item>
                <el-menu-item index="/main/certificate?from=mine" class="border br margin mi" style="height:50px">
                  课程认证
                </el-menu-item>
                <el-menu-item index="/main/favorites?from=mine" class="border br margin mi" style="height:50px">
                  我的收藏
                </el-menu-item>
                <el-menu-item index="/main/statistics?from=mine" class="border br margin mi" style="height:50px">
                  练习统计
                </el-menu-item>
              </el-menu>
            </div>
          </div>
        </div>
      </div>

    </el-card>


    <div v-else class="margin-lt flex">

      <div class="flex1 margin-r">

        <el-card>
          <div v-if="$store.state.mode == 'PTE'">
            <div class="flex flex-between flex-v-center">
              <h3>{{ $t('学习目标') }}</h3>
              <el-button icon="el-icon-edit-outline" @click="$refs.goalDialog.show()" size="mini"
                type="primary">{{ $t('设定目标') }}</el-button>
            </div>
            <div class="flex flex-v-center">
              <div class="center flex1 padding">
                <h2 class="flex flex-v-center flex-h-center"
                  v-if="$store.state.user.exam_data && $store.state.user.exam_data[4]">{{
                    $store.state.user.exam_data[4]
                  }}<span class="mt margin-ls">({{ $store.state.user.exam_data[10] }})</span></h2>
                <div class="st pointer ul" v-else @click="$refs.goalDialog.show()" size="mini"><i
                    class="el-icon-edit-outline"></i></div>
                <div class="margin-t">{{ $t('目标分数') }}</div>
              </div>
              <div class="center flex1 padding">
                <h2 v-if="$store.state.user.exam_data && $store.state.user.exam_data[9]">{{
                  $store.state.user.exam_data[9]
                  }}</h2>
                <div class="pointer" v-else @click="$refs.goalDialog.show()" size="mini"><i
                    class="el-icon-edit-outline st"></i></div>
                <div class="margin-t">{{ $t('历史成绩') }}</div>
              </div>
              <div class="center flex1 padding">
                <div v-if="$store.getters.examDays() >= 0" class="flex flex-v-center flex-h-center">
                  <h2>{{ $store.getters.examDays() }}</h2>
                  <span>天</span>
                </div>
                <div class="pointer" v-else @click="$refs.goalDialog.show()" size="mini"><i
                    class="el-icon-edit-outline st"></i></div>

                <div class="margin-t">{{ $t('距考试') }}</div>
              </div>
            </div>
          </div>


          <div v-if="$store.state.mode == 'CCL'">
            <div class="flex flex-between flex-v-center">
              <h3>{{ $t('今日目标') }}</h3>
              <el-button icon="el-icon-edit-outline" @click="to('/main/plan')" size="mini"
                type="primary">{{ $t('设置计划') }}</el-button>
            </div>
            <div class="flex flex-v-center">
              <div class="center flex1 padding">
                <h2>{{ finish_quantity }}</h2>
                <div class="margin-t">{{ $t('完成目标') }}</div>
              </div>
              <div class="center flex1 padding">
                <h2>{{ quantity }}</h2>
                <div class="margin-t">{{ $t('今日目标') }}</div>
              </div>

              <div class="center flex1 padding">
                <div class="flex flex-v-center flex-h-center">
                  <h2>{{ quantity == 0 ? 0 : parseInt(finish_quantity / quantity) + '%' }}</h2>
                </div>
                <div class="margin-t">{{ $t('完成率') }}</div>
              </div>
            </div>
          </div>

          <h3 style="margin-top:24px;">{{ $t('学习数据') }}</h3>

          <div class="flex flex-v-center">
            <div class="center flex1 padding">
              <h2>{{ items[0] }}</h2>
              <div class="margin-t">{{ $t('今日练习次数') }}</div>
            </div>

            <div class="center flex1 padding">
              <h2>{{ items[11] }}</h2>
              <div class="margin-t">{{ $t('累计练习次数') }}</div>
            </div>

            <div class="center flex1 padding">
              <h2>{{ items[12] }}</h2>
              <div class="margin-t">{{ $t('练习天数') }}</div>
            </div>
          </div>

          <!-- <div class="flex flex-between padding">
            <div>累计学习{{ items[1] }}题</div>
            <div class="color-gray">({{ items[3] }}%题库)</div>
          </div> -->
          <div>
            <el-progress status="primary" :text-inside="true" :stroke-width="26" :percentage="items[2]"></el-progress>
          </div>
        </el-card>
      </div>





      <div class="flex1 margin-l">

        <el-card class="calendar">
          <Calendar @changeMonth="monthChange" :markDateMore="markDateMore" :sundayStart="false">
          </Calendar>
        </el-card>
      </div>
    </div>


    <div class="margin-lt">
      <h3 class="margin">{{ $t('学习工具') }}</h3>
      <el-row :gutter="10">
        <el-col :span="6" class="flex1">
          <el-card class="hover">
            <div class="box1 flex" @click="to('/main/wordbook')">
              <el-image src="/web/image/main/1.png" fit="contain" class="icon0"></el-image>
              <div class="margin-l flex flex-column">
                <h4>{{ $t('单词本') }}</h4>
                <div class="st">{{ $t('涵盖所有') }}{{ $store.state.mode }}{{ $t('高频机经单词') }}</div>
              </div>
            </div>
          </el-card>
        </el-col>


        <el-col :span="6" class="flex1">
          <el-card class="hover">
            <div @click="walkman" class="box1 flex">
              <el-image src="/web/image/main/2.png" fit="contain" class="icon0"></el-image>
              <div class="margin-l flex flex-column">
                <h4>{{ $store.state.mode }}{{ $t('随身听') }}</h4>
                <div class="st">{{ $t('听力利器，合理利用碎片时间') }}</div>
              </div>
            </div>
          </el-card>
        </el-col>


        <el-col :span="6" class="flex1">
          <el-card class="hover">
            <div class="box1 flex" @click="showQrcode()">
              <el-image src="/web/image/main/3.png" fit="contain" class="icon0"></el-image>
              <div class="margin-l flex flex-column">
                <h4>{{ $t('成绩单分析') }}</h4>
                <div class="st">{{ $t('分析成绩单，提供备考建议') }}</div>
              </div>
            </div>
          </el-card>
        </el-col>

        <el-col :span="6" class="flex1">
          <el-card class="hover">
            <div class="box1 flex" @click="toMock()">
              <el-image src="/web/image/main/4.png" fit="contain" class="icon0"></el-image>
              <div class="margin-l flex flex-column">
                <h4>{{ $store.state.mode }}{{ $t('模考') }}</h4>
                <div class="st">{{ $t('真实考试流程，感受临场考试') }}</div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>




    <div class="margin-lt" v-if="records.length > 0">

      <div class="flex flex-between flex-v-center">
        <h3 class="margin">{{ $t('历史练习记录') }}</h3>
        <el-link class="margin" @click="to('/main/record')">{{ $t('更多记录') }}<i class="el-icon-arrow-right"></i></el-link>
      </div>


      <el-row :gutter="15">
        <el-col :span="12">
          <template v-for="(item, index0) in records">
            <el-card v-if="index0 % 2 == 0" class="margin-b" :key="index0">
              <div slot="header" class="flex flex-between">
                <span class="mt bold">{{ item.day }}</span>
                <span class="mt margin-r">{{ $t('练习题数') }}: {{ item.count }} {{ $t('题') }}</span>
              </div>

              <el-menu default-active="0" router>
                <el-menu-item :id="type.tag" v-for="(type, index1) in item.items" :key="index1"
                  :index="'/main/record/detail?day=' + item.day + '&tag=' + type.tag" class="border br margin"
                  style="height:60px">
                  <div class="flex flex-between flex-v-center" style="height:100%">
                    <div class="flex flex-v-center">
                      <el-image :src="$host + type.logo" style="width:40px;height: 40px;"></el-image>
                      <span class="margin-x st1 bold">{{ type.tag }}</span>
                    </div>
                    <span class="mt">{{ $t('已练') }}: {{ type.count }} {{ $t('题') }}<i
                        class="el-icon-arrow-right"></i></span>
                  </div>

                </el-menu-item>
              </el-menu>
            </el-card>
          </template>
        </el-col>

        <el-col :span="12">
          <template v-for="(item, index0) in records">
            <el-card v-if="index0 % 2 == 1" class="margin-b" :key="index0">
              <div slot="header" class="flex flex-between">
                <span class="mt bold">{{ item.day }}</span>
                <span class="mt margin-r">{{ $t('练习题数') }}:{{ item.count }} {{ $t('题') }}</span>
              </div>

              <el-menu default-active="0" router>
                <el-menu-item :id="type.tag" v-for="(type, index1) in item.items" :key="index1"
                  :index="'/main/record/detail?day=' + item.day + '&tag=' + type.tag" class="border br margin"
                  style="height:60px">
                  <div class="flex flex-between flex-v-center" style="height:100%">
                    <div class="flex flex-v-center">
                      <el-image :src="$host + type.logo" style="width:40px;height: 40px;"></el-image>
                      <span class="margin-x st1 bold">{{ type.tag }}</span>
                    </div>
                    <span class="mt">{{ $t('已练') }}: {{ type.count }} {{ $t('题') }}<i
                        class="el-icon-arrow-right"></i></span>
                  </div>

                </el-menu-item>
              </el-menu>
            </el-card>
          </template>
        </el-col>
      </el-row>

      <!-- <el-row :gutter="20">
        <el-col :span="4">
          <el-card class="hover">
            <div class="center">
              <el-image src="/web/image/main/2-1.png" class="icon1"></el-image>
              <h4 class="margin-t">了解PTE</h4>
            </div>
          </el-card>
        </el-col>


        <el-col :span="4">
          <el-card class="hover">
            <div class="center">
              <el-image src="/web/image/main/2-4.png" class="icon1"></el-image>
              <h4 class="margin-t">了解口语</h4>
            </div>
          </el-card>
        </el-col>


        <el-col :span="4">
          <el-card class="hover">
            <div class="center">
              <el-image src="/web/image/main/2-5.png" class="icon1"></el-image>
              <h4 class="margin-t">了解写作</h4>
            </div>
          </el-card>
        </el-col>




        <el-col :span="4">
          <el-card class="hover">
            <div class="center">
              <el-image src="/web/image/main/2-3.png" class="icon1"></el-image>
              <h4 class="margin-t">了解阅读</h4>
            </div>
          </el-card>
        </el-col>

        <el-col :span="4">
          <el-card class="hover">
            <div class="center">
              <el-image src="/web/image/main/2-2.png" class="icon1"></el-image>
              <h4 class="margin-t">了解听力</h4>
            </div>
          </el-card>
        </el-col>



        <el-col :span="4">
          <el-card class="hover">
            <div class="center">
              <el-image src="/web/image/main/2-6.png" class="icon1"></el-image>
              <h4 class="margin-t">模板&机经</h4>
            </div>
          </el-card>
        </el-col>
      </el-row> -->
    </div>
    <goalDialog ref="goalDialog"></goalDialog>
    <el-dialog :visible.sync="dialogVisible" width="480px" center>
      <h3 slot="title">{{ $t('扫码分析成绩单') }}</h3>
      <el-image style="width:100%;height:auto;" :src="$host + $store.state.setting.kf_qrcode"></el-image>
    </el-dialog>
  </div>
</template>
<script>
// import moment from 'moment'
import Calendar from 'vue-calendar-component';
import goalDialog from '../../components/GoalDialog.vue';
export default {


  components: {
    Calendar, goalDialog
  },
  data() {
    return {
      tab: 0,
      drawer: false,
      items: [],
      markDateMore: [],
      dialogVisible: false,
      finish_quantity: 0,
      quantity: 0,
    };
  },

  created() {
    // this.days = moment(this.$store.state.user.exam_date).diff(moment(), "days");
    this.query();
    this.query_record();
    this.monthChange();
  },

  computed: {
    records: function () {
      return this.$store.state.mineRecords;
    }
  },


  methods: {
    toMock() {
      if (this.$store.state.mode == "CCL") {
        this.$router.push('/main/ccl?tag=mock')
      } else {
        this.$router.push('/main/mocks')
      }
    },

    showQrcode() {
      this.dialogVisible = true;
    },
    monthChange(val) {
      this.markDateMore = this.$store.getters.examDate();
      this.$http.post("/api/user/calendar", { date: val }).then((items) => {
        if (items.length > 0) {
          items.forEach(item => {
            this.markDateMore.push({ date: item.date, className: "mark2" })
          });
        }
      })
    },

    to(url) {
      this.$router.push(url);
    },
    walkman() {
      window.open("/walkman");
    },

    query_record() {
      this.$http.post("/api/source/record/mine", { tag: 'index' }).then((records) => {
        this.$store.commit("setMineRecords", records)
      })
    },

    query() {
      this.$http.post("/api/user/data").then((items) => {
        this.items = items;
      })

      if (this.$store.state.mode == 'CCL') {
        this.quantity = 0;
        this.finish_quantity = 0;
        this.$http.post("/api/today/plans").then((data) => {
          if (data) {
            data.forEach(item => {
              this.quantity += item.quantity;
              this.finish_quantity += item.finish_quantity;
            });
          }
        })
      }
    }
  },
};
</script>

<style scoped>
a {
  color: #303030;
}

.adv {
  height: 60px;
  padding: 0 20px;
  background: linear-gradient(90deg, #f57a54, #ff9f7b);
  margin-bottom: 20px;
  opacity: .9;
  border-radius: 20px;
}


.top>>>.el-input__inner {
  border-radius: 20px;
  /* background-color: #f0f0f0; */
  /* border: none; */
}

.drawer {
  padding: 30px 13%;
}

.hover:hover {
  cursor: pointer;
  position: relative;
  top: -3px;
}

.icon0 {
  flex-shrink: 0;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}


.icon1 {
  width: 60px;
  height: 60px;
}

.drawer>>>.el-input__inner {
  border-radius: 20px;
  border-color: #eeeeee;
}


.box1 {
  height: 60px;
}

.calendar {
  height: 338px;
}

.calendar>>>.wh_content_item .wh_chose_day {
  background: #fff;
}

.calendar>>>.el-card__body {
  padding: 0;
}

.calendar>>>.wh_content_all {
  background-color: #fff;
}

.calendar>>>.wh_item_date,
.calendar>>>.wh_content_li,
.calendar>>>.wh_top_tag {
  color: #000;
}

.calendar>>>.wh_other_dayhide {
  color: #999;
}

.calendar>>>.wh_content_item .wh_isToday,
.calendar>>>.wh_item_date:hover {
  background: #E6A23C;
  color: #fff;
}

.calendar>>>.wh_jiantou1 {
  border-top-color: #000;
  border-left-color: #000;
}

.calendar>>>.wh_jiantou2 {
  border-top-color: #000;
  border-right-color: #000;
}



.py {
  padding-top: 10px;
  padding-bottom: 10px;
}

.mi {
  height: 50px;
  line-height: 50px;
}

.avatar {
  width: 100px;
  width: 100px;
  border-radius: 50%;
}
</style>
