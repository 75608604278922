<template>
    <div>
        <el-card>
            <h3 slot="header">{{ $t('资料下载') }}</h3>
            <el-empty v-if="list.length == 0" :description="$t('暂无下载资料') + '!'"></el-empty>
            <div v-else class="document">
                <el-row :gutter="40">
                    <el-col :span="12" v-for="(item, index) in list" :key="index">
                        <el-card class="box-card margin-b">
                            <div slot="header" class="clearfix flex flex-between flex-v-center padding">
                                <span class="bold mt">{{ item.title }}</span>

                                <div class="flex flex-v-cener">
                                    <el-link :disabled="item.page == 1"><i class="el-icon-arrow-left"
                                            @click="prev(item)"></i></el-link>
                                    <span class="margin-ls">{{ item.page }}</span>
                                    <span class="margin-ls">/</span>
                                    <span class="margin-ls">{{ Math.ceil(item.documents.length / 10) }}</span>
                                    <el-link :disabled="Math.ceil(item.documents.length / 10) == item.page"
                                        class="margin-ls"><i @click="next(item)"
                                            class="el-icon-arrow-right "></i></el-link>
                                </div>

                            </div>
                            <div @click="download(doc)"
                                v-for="(doc, index1) in item.documents.slice((item.page - 1) * 10, item.page * 10)"
                                :key="index1" class="text doc-item flex flex-between">
                                <span class="mt"> {{ doc.title }}</span>
                                <i v-if="doc.flag == 1 && $store.getters.getRole() == 0"
                                    class="el-icon-lock icon-l"></i>
                                <i v-else-if="doc.flag == 1 && $store.getters.getRole() > 0"
                                    class="el-icon-unlock icon-l"></i>
                                <i v-else class="el-icon-download icon-l"></i>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },

    created() {
        this.$store.dispatch("fetch_documents");
    },

    computed: {
        list: function () {
            const type = this.$store.state.mode == 'CCL' ? 1 : 0;
            return this.$store.state.documents.filter((val)=>{return val.type == type});
        }
    },

    methods: {

        prev(item) {
            if (item.page == 1) return;
            item.page--;
        },

        next(item) {
            if (item.page == Math.ceil(item.documents.length / 10)) return;
            item.page++;
        },

        download(item) {
            if (item.flag == 1 && this.$store.getters.getRole() == 0) {
                this.$vip_alert("此功能", "")
                return;
            }
            window.open(this.$host + this.$url(item, 'path'));
        },


        create() {
            this.centerDialogVisible = false;
            window.open(this.$host + "/api/pdf/" + this.item.uuid);
        },


    },
};
</script>
<style scoped>
.document>>>.el-card__header {
    padding: 10px 20px;
}

.doc-item {
    cursor: pointer;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #f0f0f0;
    margin-bottom: 15px;
}



.doc-item:hover,
.doc-item.on {
    border: 1px solid #e6a13c;
    color: #e6a13c;
}
</style>
