<template>
    <div class="drawer" v-loading="loading">
        <!-- <div class="flex flex-h-center flex-v-center">
            <h3 class="lt margin-x">全题库搜索</h3>
            <el-input clearable class="margin-x"
                style="width:300px;background-color: #ccc; border-radius: 20px;font-size: 18px;"
                placeholder="请输入题目/编号/关键词搜索" @change="query()" v-model="search">
            </el-input>
            <el-button @click="query()" type="primary" icon="el-icon-search" round>查询</el-button>
        </div> -->

        <el-tabs v-model="sort" @tab-click="handleClick" class="margin-t">
            <el-tab-pane :label="$t('全部')" name="0"></el-tab-pane>
            <el-tab-pane v-for="(sort, index) in $store.state.sorts" :key="index" :label="sort.title"
                :name="sort.title">
                <el-radio-group @change="typeChange" text-color="#E6A23C" v-model="type">
                    <el-radio text-color="#E6A23C" class="margin-l margin-t" v-for="(type, index1) in sort.types"
                        :key="index1" :label="type.tag">{{ type.title }}</el-radio>
                </el-radio-group>
            </el-tab-pane>
        </el-tabs>

        <el-divider v-if="sort != 0"></el-divider>

        <!-- <div class="flex padding flex-v-center" flex-h-center v-for="(sort, index) in $store.state.sorts" :key="index">
            <h3 class="margin-x type">{{ sort.title }}</h3>
            <div v-for="(type, index1) in sort.types" :key="index1" @click="checkTag(type.tag)"
                class="type margin-x pointer" :class="(tags.indexOf(type.tag) != -1) ? 'checked' : ''">{{
                    type.tag
                }}</div>
        </div> -->

        <el-card shadow="never" v-for="(source, index) in list" :key="index" class="margin-t">
            <div slot="header" class="flex flex-between flex-v-center">
                <div class="flex flex-v-center">
                    <el-tag type="primary" class="margin-r" size="small" effect="dark">{{ source.tag }}</el-tag>
                    <h4 class="bold">
                        <span>{{ index + 1 }}. </span>
                        <span v-if="source.title && source.title.indexOf('^') == -1">
                            {{ source.title }}
                        </span>
                        <span class="mt ls1" v-else>
                            <span v-for="(item, index) in source.title.split('^')" :key="index"
                                v-html="index % 2 == 0 ? item : ('<bold>' + item + '</bold>')"></span>
                        </span>
                    </h4>

                    <div class="flex">
                        <el-tag size="small" type="warning" class="margin-l">#{{ source.no }}</el-tag>
                        <el-tag size="small" v-if="source.frequency == 1" class="margin-ls" type="warning">{{ $t('高频')
                            }}</el-tag>

                        <el-tag size="small" v-if="source.is_gmat" class="margin-ls" type="warning">{{ $t('机经题')
                            }}</el-tag>

                        <el-tag size="small" v-if="source.is_forecast" class="margin-ls" type="warning">{{ $t('预测题')
                            }}</el-tag>
                        <el-tag size="small" v-if="source.is_new" class="margin-ls" type="warning">{{ $t('新题')
                            }}</el-tag>

                        <el-tag size="small" v-if="source.level == 2" class="margin-ls" type="warning">{{ $t('难')
                            }}</el-tag>
                        <el-tag size="small" v-else-if="source.level == 1" class="margin-ls" type="warning">{{ $t('中')
                            }}</el-tag>
                        <el-tag size="small" v-else class="margin-ls" type="warning">{{ $t('简') }}</el-tag>
                        <el-tag size="small" v-if="source.is_back" class="margin-ls" type="warning">{{ $t('老题重回')
                            }}</el-tag>
                        <el-tag size="small" v-if="source.is_updated" class="margin-ls" type="warning">{{ $t('更新')
                            }}</el-tag>
                    </div>
                </div>

                <el-tag effect="dark" type="primary" round size="small" v-if="source.my_count > 0">{{ $t('已练习')
                    }}</el-tag>
                <el-tag v-else effect="dark" type="warning" round size="small">{{ $t('未练习') }}</el-tag>
            </div>

            <div v-if="source.words_en">
                <div v-html="source.words_en" class="mt ls1" v-if="source.words_en.indexOf('^') == -1">
                </div>

                <div class="mt ls1" v-else>
                    <span v-for="(item, index) in source.words_en.split('^')" :key="index"
                        v-html="index % 2 == 0 ? item : ('<bold>' + item + '</bold>')"></span>
                </div>
            </div>


            <div class="margin-ts" v-if="source.tag == 'WFD' && source.analysis">

                <div class="mt ls1" v-for="(row, index0) in source.analysis.split('\n')" :key="index0">
                    <div v-if="index0 == 0">{{ $store.getters.typeSettings(source.tag)[0] + ":" }}</div>
                    <span v-for="(item, index) in row.split('^')" :key="index"
                        v-html="index % 2 == 0 ? item : ('<bold>' + item + '</bold>')"></span>
                </div>
            </div>

            <div class="margin-t flex flex-v-center flex-between">
                <div class="flex flex-v-center">
                    <div class="st">{{ $t('已练习次数') }}：{{ source.record_count }}({{ $t('人次') }})</div>
                    <el-divider direction="vertical"></el-divider>
                    <div class="st">{{ $t('收藏人数') }}：{{ source.favorite_count }}({{ $t('人') }})</div>
                    <el-divider direction="vertical"></el-divider>
                    <div class="st">{{ $t('考过') }}：{{ source.exam_count }}</div>
                </div>
                <el-link size="small" @click="toWork(source)" type="primary" class="margin-x">{{ $t('去练习') }}<i
                        class="el-icon-right"></i></el-link>
            </div>

        </el-card>

        <el-empty v-if="list.length == 0"></el-empty>

        <div class="center margin-t">
            <el-pagination background @current-change="pageChange" layout="prev, pager, next,total,jumper"
                :total="total">
            </el-pagination>
        </div>

    </div>
</template>

<script>
export default {
    name: "search",
    data() {
        return {
            search: "",
            tags: [],
            list: [],
            total: 0,
            sort: "0",
            page: 1,
            type: "",
            lastSearch: "",
            loading: false,
        };
    },


    activated() {
        if (this.$route.query.search) {
            this.search = this.$route.query.search;
            this.query();
            this.$store.commit("setSearch", null);
        }
    },


    created() {
        this.$bus.$emit("aside", false);
        this.$bus.$on("search", (search) => {
            // this.sort = "0";
            // this.type = ""
            this.search = search;
            this.query();
        })

        if (this.$route.query.search) {
            this.search = this.$route.query.search;
            this.query();
            this.$store.commit("setSearch", null);
        } else {
            if (this.$store.state.search) {
                this.search = this.$store.state.search;
                this.query();
                this.$store.commit("setSearch", null);
            } else {
                if (this.$store.state.searchData) {
                    this.search = this.$store.state.searchData.search;
                    this.lastSearch = this.$store.state.searchData.search;
                    this.sort = this.$store.state.searchData.sort;
                    this.list = this.$store.state.searchData.list;
                    this.total = this.$store.state.searchData.total;
                }
            }
        }

    },
    methods: {

        toWork(source) {
            if (source.type == 1) {
                this.$router.push("/main/ccl?tag=" + source.tag + "&no=" + source.no)
            } else {
                this.$router.push("/main/pte?tag=" + source.tag + "&no=" + source.no)
            }
        },

        typeChange() {
            if (this.search.trim().length > 1) {
                this.query(1);
            }
        },

        handleClick() {
            this.type = "";
            if (this.search.trim().length > 1) {
                this.query(1)
            }
        },

        pageChange(page) {
            this.page = page;
            this.query();
        },

        query(page) {
            if (this.search.trim().length == 0) {
                this.list = [];
                this.total = 0;
                return;
            }
            if (page) this.page = page;
            if (this.search.trim().length > 1) {
                this.loading = true;
                this.$http.post("/api/source/search", { search: this.search, sort: this.sort, type: this.type, page: this.page }).then((res) => {
                    this.lastSearch = this.search.trim();
                    this.list = res.data;
                    this.total = res.total;
                    this.loading = false;
                    let answers = []
                    this.list.forEach((item) => {
                        item.title = item.title.replace(new RegExp(this.lastSearch, 'gi'), (word) => {
                            return "^" + word + "^"
                        });
                        if (item.tag == 'FIB-RW' || item.tag == 'FIB-R') {
                            answers = item.answer.split(',')
                            let words_en = ""
                            item.question.split(/\([^)]+\)/g).forEach((words, index) => {
                                words_en += words
                                if (answers[index]) {
                                    words_en += "<span class=\"underline\">" + answers[index] + "</span>"
                                }

                            })
                            item.words_en = words_en
                        } else if (item.tag == 'FIB-L') {
                            answers = item.answer.split(',')
                            let words_en = ""
                            item.question.split(/__/g).forEach((words, index) => {
                                words_en += words
                                if (answers[index]) {
                                    words_en += "<span class=\"underline\">" + answers[index] + "</span>"
                                }
                            })
                            item.words_en = words_en
                        }

                        if (item.words_en) {
                            item.words_en = item.words_en.replaceAll('^', '').replace(new RegExp(this.lastSearch, 'gi'), (word) => {
                                return "^" + word + "^"
                            });
                        }


                        if (item.tag == "WFD" && item.analysis) {
                            item.analysis = item.analysis.replaceAll('^', '').replace(new RegExp(this.lastSearch, 'gi'), (word) => {
                                return "^" + word + "^"
                            });
                        }
                        let parts = this.lastSearch.split(" ");
                        if (parts.length >= 2) {
                            parts.forEach((part) => {
                                item.title = item.title.replace(new RegExp(part, 'gi'), (word) => {
                                    return "^" + word + "^"
                                });
                                if (item.words_en) {
                                    item.words_en = item.words_en.replace(new RegExp(part, 'gi'), (word) => {
                                        return "^" + word + "^"
                                    });
                                }
                            })
                        }

                        item.title = item.title.replace(/\^+/gi, '^');
                        item.words_en = item.words_en.replace(/\^+/gi, '^');
                    })
                    this.$store.commit("setSearchData", { search: this.lastSearch, sort: this.sort, list: this.list, total: res.total });
                })
            } else {
                this.$message({
                    message: this.$t('最少输入两个字母'),
                    type: 'primary'
                });
            }
        },
        checkTag(tag) {
            if (this.tags.indexOf(tag) == -1) {
                this.tags.push(tag)
            } else {
                this.tags.splice(this.tags.indexOf(tag), 1);
            }
        }
    },
};
</script>
<style scoped>
.drawer>>>bold {
    background-color: yellow;
    font-size: 18px;
}

.drawer {
    padding: 30px;
}

.drawer>>>.el-input__inner {
    border-radius: 20px;
    border-color: #eeeeee;
}

.drawer>>>.el-tabs__item.is-active,
.drawer>>>.el-radio__input.is-checked+.el-radio__label,
.drawer>>>.el-tabs__item:hover {
    color: #E6A23C;
}

.drawer>>>.el-tabs__active-bar {
    background-color: #E6A23C;
}

.drawer>>>.el-radio__input.is-checked .el-radio__inner {
    border-color: #E6A23C;
    background: #E6A23C;
}

.drawer>>>.underline {
    text-decoration: underline;
    text-decoration-color: #E6A23C;
    /* 红色下划线 */
    text-underline-offset: 5px;
}
</style>
