import moment from 'moment'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import request from '../request'

export default new Vuex.Store({
    state: {
        user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
        sorts: localStorage.getItem("sorts") ? JSON.parse(localStorage.getItem("sorts")) : [],
        types: localStorage.getItem("types") ? JSON.parse(localStorage.getItem("types")) : [],
        courses: [],
        teachers: [],
        articleTypes: [],
        articles: [],
        points: [],
        words: [],
        books: [],
        bookSorts: [],
        vips: [],
        mineBooks: [],
        courseMedias: [],
        articleMedias: [],
        wordData: [],
        articleType: 0,
        search: '',
        searchData: null,
        vocabularyPage: 1,
        setting: { banners: [] },
        cclTypes: [],
        enVoice: localStorage.getItem("en_audio_voice") ? localStorage.getItem("en_audio_voice") : "Origin,原声",
        zhVoice: localStorage.getItem("zh_audio_voice") ? localStorage.getItem("zh_audio_voice") : "Origin,原声",
        mockTypes: [],
        wordDialogShow: false,
        mode: localStorage.getItem("mode") || "PTE",
        documents: [],
        mineRecords: [],
        records: [],
        lang: localStorage.getItem("web_lang") || "",
        isTw: false,
    },
    mutations: {
        setIsTw(state, val) {
            state.isTw = val;
            if (val) {
                state.lang = "tw";
            }
        },
        setLang(state, lang) {
            localStorage.setItem("web_lang", lang);
            state.lang = lang;
        },
        setUser(state, user) {
            if (user) {
                localStorage.setItem("user", JSON.stringify(user));
            } else {
                localStorage.clear();
            }
            state.user = user;
        },

        setRecords(state, val) {
            state.records = val;
        },

        setMineRecords(state, val) {
            state.mineRecords = val;
        },

        setWordDialogShow(state, val) {
            state.wordDialogShow = val;
        },


        setDocuments(state, val) {
            state.documents = val;
        },

        setMode(state, val) {
            localStorage.setItem("mode", val);
            state.mode = val;
        },

        setEnVoice(state, voice) {
            state.enVoice = voice;
        },
        setZhVoice(state, voice) {
            state.zhVoice = voice;
        },

        setSorts(state, sorts) {
            state.sorts = sorts;
        },

        setTypes(state, types) {
            state.types = types;
        },

        setMockTypes(state, types) {
            state.mockTypes = types;
        },

        setSetting(state, setting) {
            state.setting = setting;
        },

        setPoints(state, points) {
            state.points = points;
        },

        setCourses(state, courses) {
            state.courses = courses;
        },

        setTeachers(state, teachers) {
            state.teachers = teachers;
        },

        setVips(state, vips) {
            state.vips = vips;
        },
        setCclTypes(state, types) {
            state.cclTypes = types;
        },
        setSearch(state, search) {
            state.search = search;
        },
        setSearchData(state, data) {
            state.searchData = data;
        },

        setArticleTypes(state, types) {
            state.articleTypes = types;
        },

        saveMedia(state, data) {
            if (data[0] == "article") {
                let index = state.articleMedias.findIndex(item => item.id == data[1].id);
                if (index == -1) {
                    state.articleMedias.push(data[1])
                } else {
                    state.articleMedias[index] = data[1];
                }
            } else {
                let index = state.courseMedias.findIndex(item => item.id == data[1].id);
                if (index == -1) {
                    state.courseMedias.push(data[1])
                } else {
                    state.courseMedias[index] = data[1];
                }
            }
        },

        setArticles(state, articles) {
            state.articles = articles;
        },

        set_vocabulary_page(state, page) {
            state.vocabularyPage = page;
        },

        setArticleType(state, type) {
            state.articleType = type;
        },
        setBooks(state, books) {
            state.books = books;
        },

        setBookSorts(state, sorts) {
            state.bookSorts = sorts;
        },


        setBook(state, data) {
            console.log(data)
            if (data[0] == "mine" && data[1] > 0) {
                state.mineBooks.forEach((item) => {
                    if (item.id == data[1]) {
                        item.record_count = data[2];
                    }
                });
            } else {
                state.books.forEach((item) => {
                    if (item.tag == data[0]) {
                        item.record_count = data[2];
                    }
                });
            }
        },

        updateBook(state, data) {
            console.log(data)
            if (data[0] == "mine" && data[1] > 0) {
                state.mineBooks.forEach((item) => {
                    if (item.id == data[1]) {
                        if (data[2] == 0) {
                            item.record_count = data[2];
                        } else {
                            item.record_count += data[2];
                        }

                    }
                });
            } else {
                state.books.forEach((item) => {
                    if (item.tag == data[0]) {
                        if (data[2] == 0) {
                            item.record_count = data[2];
                        } else {
                            item.record_count += data[2];
                        }
                    }
                });
            }
        },

        setMineBooks(state, books) {
            state.mineBooks = books;
        },

        setWordData(state, data) {
            state.wordData = data;
        },

        addWord(state, word) {
            state.words.push(word);
        },

        updateWord(state, word) {
            console.log(word);
            for (let index = state.words.length - 1; index >= 0; index--) {
                if (state.words[index].id == word.id) {
                    state.words[index] = word;
                    break;
                }
            }
        },
    },
    getters: {
        getVips: (state) => () => {
            if (state.mode == 'CCL') {
                return state.vips.filter(val => { return val.money > 0 })
            }
            return state.vips;
        },


        getSorts: (state) => (type) => {
            return state.sorts.filter(sort => sort.type == type) || [];
        },
        getVoice: (state) => (lang) => {
            if (lang == "zh") {
                return state.zhVoice.split(",")[0];
            }
            return state.enVoice.split(",")[0];
        },

        getBooks: (state) => (type) => {
            return state.books.filter(book => book.type == type) || [];
        },

        getVoiceLabel: (state) => (lang) => {
            if (lang == "zh") {
                return state.zhVoice.split(",")[1];
            }
            return state.enVoice.split(",")[1];
        },

        getVip: (state) => () => {
            let flag = "未购买VIP";
            if (state.mode == "CCL") {
                if (state.user.ccl_endtime) {
                    if (moment(state.user.ccl_endtime).diff(moment(), "days") >= 0) {
                        flag = "到期日：" + state.user.ccl_endtime;
                    } else {
                        flag = "VIP 已过期";
                    }
                }
            } else {
                if (state.user.vip_endtime) {
                    if (moment(state.user.vip_endtime).diff(moment(), "days") >= 0) {
                        flag = "到期日：" + state.user.vip_endtime;
                    } else {
                        flag = "VIP 已过期";
                    }
                }
            }

            return flag;
        },

        getType: (state) => (tag) => {
            return state.types.find(type => type.tag === tag) || {};
        },
        getCclType: (state) => (tag) => {
            return state.cclTypes.find(type => type.tag === tag) || { title: '' };
        },

        getTypes: (state) => (val) => {
            if (typeof (val) == 'number') {
                return state.types.filter(item => item.type == val) || [];
            } else {
                return state.types.filter(item => item.sort == val) || [];
            }

        },

        getForecastTypes: (state) => (sort) => {
            return state.types.filter(type => type.sort === sort && type.forecast_flag == 1)
        },

        getWord: (state) => (word) => {
            return state.words.find(item => item.word === word)
        },

        typeSettings: (state) => (tag) => {
            let type = state.types.find(type => type.tag == tag);
            if (type && type.settings) {
                return type.settings.split(",");
            }
            return [''];
        },
        getBook: (state) => (tag, book_id) => {
            if (tag == "mine") {
                if (book_id == 0) {
                    return state.books[0];
                }
                return state.mineBooks.find(item => item.id == book_id)
            } else {
                return state.books.find(item => item.tag === tag)
            }
        },

        getBookName: (state) => (tag) => {
            let book = state.books.find(item => item.tag === tag);
            return book ? book.title : '';
        },

        getArticle: (state) => (id) => {
            return state.articles.find(item => item.id == id)
        },

        getSort: (state) => (tag) => {
            let type = state.types.find(item => item.tag == tag)
            return type ? type.sort : "Speaking"
        },

        getArticles: (state) => (type_id) => {
            return state.articles.filter(item => item.type_id == type_id)
        },
        getCourse: (state) => (id) => {
            return state.courses.find(item => item.id == id)
        },
        getCourses: (state) => (tag) => {
            return state.courses.filter(item => item.tag == tag)
        },
        getMedia: (state) => (tag, id) => {
            if (tag == 'article') {
                return state.articleMedias.find(item => item.id == id)
            } else {
                return state.courseMedias.find(item => item.id == id)
            }
        },

        examDays: (state) => () => {
            return state.user.exam_date ? moment(state.user.exam_date).diff(moment(), "days") + 1 : '-';
        },

        examDate: (state) => () => {
            return state.user.exam_date ? [{ date: state.user.exam_date, className: "mark1" }] : [];
        },

        prediction: (state) => (tag) => {
            let type = state.types.find(type => type.tag === tag)
            if (type && type.prediction) {
                return type.prediction.split(",");
            }
            return [0, 0, 0];
        },


        getRole: (state) => () => {
            if (!state.user) return 0;
            if (state.mode == "CCL") {
                if (state.user.ccl_vip == 1) {
                    return 1;
                } else if (state.user.ccl_certificate && state.user.ccl_certificate.status == 1) {
                    return 2;
                } else {
                    return 0;
                }
            } else {
                if (state.user.vip == 1) {
                    return 1;
                } else if (state.user.certificate && state.user.certificate.status == 1) {
                    return 2;
                } else {
                    return 0;
                }
            }
        },

        getRole1: (state) => () => {
            if (state.user.ccl_certificate.status == 1 && state.user.certificate.status == 1) {
                return 4
            }else if (state.user.ccl_certificate.status == 1) {
                return 3
            }else if (state.user.certificate.status == 1) {
                return 2
            }else if (state.user.vip == 1) {
                return 1
            }
            return 0
        },


        getCertificateStatus: (state) => () => {
            if (!state.user) return -1;
            if (state.mode == "CCL") {
                if (state.user.ccl_certificate == null) {
                    return -1
                } else {
                    return 0;
                    // return state.user.ccl_certificate.status;
                }
            } else {
                if (state.user.certificate == null) {
                    return -1
                } else {
                    return state.user.certificate.status;
                }
            }
        },
        getCertificate: (state) => () => {
            if (!state.user) return { status: 0 };
            if (state.mode == "CCL") {
                return state.user.ccl_certificate;
            } else {
                return state.user.certificate;
            }
        }


    },
    actions: {

        fetch_book_sorts(context, flag) {
            if (flag || context.state.books.length == 0) {
                request.post("/api/word/sorts").then((sorts) => {
                    context.commit("setBookSorts", sorts);
                })
            }
        },


        fetch_books(context, flag) {
            if (flag || context.state.books.length == 0) {
                request.post("/api/word/types").then((books) => {
                    context.commit("setBooks", books);
                })
            }
        },
        fetch_mine_books(context, flag) {
            if (flag || context.state.mineBooks.length == 0) {
                request.post("/api/book/list").then((books) => {
                    context.commit("setMineBooks", books);
                })
            }
        },



        fetch_article_types(context) {
            if (context.state.articleTypes.length == 0) {
                request.post("/api/article/types").then((types) => {
                    context.commit("setArticleTypes", types);
                    context.commit("setArticleType", types[0].id);
                })
            }
        },

        fetch_articles(context) {
            if (context.state.articles.length == 0) {
                request.post("/api/article/list").then((list) => {
                    context.commit("setArticles", list);
                })
            }
        },

        fetch_word_data(context) {
            if (context.state.wordData.length == 0) {
                request.post("/api/word/data").then((data) => {
                    context.commit("setWordData", data);
                })
            }
        },
        fetch_vips(context) {
            if (context.state.vips.length == 0) {
                request.post("/api/vips").then((data) => {
                    context.commit("setVips", data);
                })
            }
        },
        fetch_user(context) {
            request.post("/api/user/info").then((data) => {
                context.commit("setUser", data);
            })
        },

        fetch_ccl_types(context) {
            if (context.state.cclTypes.length == 0) {
                request.post("/api/ccl/types").then((data) => {
                    context.commit("setCclTypes", data);
                })
            }
        },

        fetch_courses(context) {
            if (context.state.courses.length == 0) {
                request.post("/api/course/list").then((data) => {
                    context.commit("setCourses", data);
                })
            }
        },


        fetch_teachers(context) {
            if (context.state.teachers.length == 0) {
                request.post("/api/teachers").then((data) => {
                    context.commit("setTeachers", data);
                })
            }
        },

        fetch_mock_types(context) {
            if (context.state.mockTypes.length == 0) {
                request.post("/api/mock/types").then((data) => {
                    context.commit("setMockTypes", data);
                })
            }
        },

        fetch_documents(context) {
            if (context.state.documents.length == 0) {
                request.post("/api/documents").then((data) => {
                    data.forEach((item) => {
                        item.page = 1;
                    })
                    context.commit("setDocuments", data);
                })
            }
        },
    },
    modules: {}
});
