<template>
    <div class="flex flex-between">
        <div :style="{ 'max-height': (flag ? offsetHeight : height) + 'px' }"
            class="outer flex1 flex flex-v-center flex-wrap">
            <div :ref="'inner' + id">
                <slot></slot>
            </div>
        </div>

        <div @click="flag = !flag" v-if="offsetHeight > height"
            style="background-color: #fff; padding: 0 10px;align-items: flex-end;" class="st pointer flex">
            <i :class="flag ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" class="icon"></i>
        </div>
    </div>

</template>

<script>
export default {
    name: "RowSpan",
    data() {
        return {
            offsetHeight: 0,
            flag: false
        };
    },

    watch: {
        id() {
            this.$nextTick(() => {
                this.flag = false;
                this.offsetHeight = this.$refs['inner' + this.id].offsetHeight;
            })
        }
    },

    props: {
        height: {
            type: Number,
            default: 84
        },

        id: {
            type: Number,
            default: 0
        }
    },

    created() {

    },

    mounted() {
        this.$nextTick(() => {
            this.offsetHeight = this.$refs['inner' + this.id].offsetHeight;
        })
    },

    methods: {

    },
};
</script>
<style scoped>
.outer {
    position: relative;
    overflow: hidden;
}

.icon {
    color: #e6a13c;
    font-size: 20px;
    line-height: 28px;
}
</style>