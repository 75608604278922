<template>
  <div>
    <el-card class="header">
      <!-- <div slot="header" class="flex flex-between flex-v-center header">
        <h3>{{ $t('个人中心') }}</h3>
      </div> -->

      <div class="margin-lt flex flex-h-center">
        <div class="padding margin-x" style="max-width: 1000px;">
          <div class="flex flex-between flex-v-center">
            <div class="flex flex-h-center flex-v-center" style="width: 200px;">
              <el-upload :headers="headers" accept="image/jpeg,image/jpg,image/png" :show-file-list="false"
                class="upload-demo" :action="$host + '/api/user/avatar'" :on-success="uploadSuccess" :limit="1">
                <div slot="trigger" class="avatar-box">
                  <el-image class="avatar" :src="$store.state.user.avatar ? $store.state.user.avatar.indexOf('http') == 0 ? $store.state.user.avatar : $host + $store.state.user.avatar
                    : '/web/image/logo0.png'">
                  </el-image>
                </div>
              </el-upload>
              <div class="bold margin-l">{{ $store.state.user.nickName }}</div>
            </div>
            <div class="lt bold">
              个人中心
            </div>
            <div class="flex flex-h-center flex-v-center" style="width: 200px;">
              <el-tag v-if="$store.getters.getRole1() == 4" style="margin-right:10px;" effect="dark">{{
                $t('课程VIP') }} (PTE+CCL)</el-tag>
              <el-tag v-else-if="$store.getters.getRole1() == 3" style="margin-right:10px;" effect="dark">{{
                $t('课程VIP') }} (CCL)</el-tag>
              <el-tag v-else-if="$store.getters.getRole1() == 2"  style="margin-right:10px;" effect="dark">{{
                $t('课程VIP') }} (PTE)</el-tag>
              <el-tag v-if="$store.getters.getRole1() == 1"  style="margin-right:10px;"
                effect="dark">VIP</el-tag>
              <el-image v-if="$store.state.user.certificate.status == 1" style="width: 30px;height: auto;"
                src="/web/image/pte.png"></el-image>
              <el-image v-if="$store.state.user.ccl_certificate.status == 1" style="width: 30px;height: auto;"
                src="/web/image/ccl.jpg"></el-image>
            </div>
          </div>

          <div class="center margin-ts bold">距离考试还有：{{ $store.getters.examDays() > 0 ? $store.getters.examDays() : '-'
            }} 天
          </div>

          <div class="flex flex-between flex-v-center margin-lt">
            <div style="width: 150px;">
              <el-menu>
                <el-menu-item @click="$refs.goalDialog.show()" class="border br margin mi">
                  学习目标
                </el-menu-item>
              </el-menu>

              <div class="margin-t bold center">口 语：{{ $store.state.user.exam_data ? $store.state.user.exam_data[0] : 60
                }}
              </div>
              <div class="margin-t bold center">写 作：{{ $store.state.user.exam_data ? $store.state.user.exam_data[1] : 60
                }}
              </div>
              <div class="margin-t bold center">阅 读：{{ $store.state.user.exam_data ? $store.state.user.exam_data[2] : 60
                }}
              </div>
              <div class="margin-t bold center">听 力：{{ $store.state.user.exam_data ? $store.state.user.exam_data[3] : 60
                }}
              </div>
              <div class="margin-t bold center">总 分：{{ $store.state.user.exam_data ? $store.state.user.exam_data[4] : 60
                }}
              </div>
            </div>


            <div class="margin-x flex1" style="height: 350px;display: flex;align-items: center;">
              <div class="calendar">
                <Calendar @changeMonth="monthChange" :markDateMore="markDateMore" :sundayStart="false">
                </Calendar>
              </div>
            </div>


            <div style="width: 150px;">
              <el-menu router>
                <el-menu-item index="/main/score" class="border br margin mi">
                  我的成绩
                </el-menu-item>
                <el-menu-item index="/main/certificate?from=mine" class="border br margin mi" style="height:50px">
                  课程认证
                </el-menu-item>
                <el-menu-item index="/main/favorites?from=mine" class="border br margin mi" style="height:50px">
                  我的收藏
                </el-menu-item>
                <el-menu-item index="/main/statistics?from=mine" class="border br margin mi" style="height:50px">
                  练习统计
                </el-menu-item>
              </el-menu>
            </div>
          </div>
        </div>
      </div>

    </el-card>

    <goalDialog ref="goalDialog"></goalDialog>

  </div>
</template>
<script>

import Calendar from 'vue-calendar-component';
import goalDialog from '../../components/GoalDialog.vue';


export default {
  components: { Calendar, goalDialog },
  data() {
    return {
      markDateMore: [],
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
  },


  created() {
    let code = this.$route.query.code;
    if (code) {
      this.$http.post("/api/login/wechat", { code: code, tag: "bind" }).then((user) => {
        this.$store.commit("setUser", user);
        this.$message({
          message: this.$t('绑定成功') + '!',
          type: 'success'
        });
      });
    }
    this.timezone = this.$store.state.user.timezone;
    this.monthChange()

  },

  computed: {
  },


  methods: {
    to(url) {
      if (url) {
        this.$router.push(url)
      } else {
        this.$message.warning('功能开发中...')
      }
    },
    monthChange(val) {
      this.markDateMore = this.$store.getters.examDate();
      this.$http.post("/api/user/calendar", { date: val }).then((items) => {
        if (items.length > 0) {
          items.forEach(item => {
            this.markDateMore.push({ date: item.date, className: "mark2" })
          });
        }
      })
    },
    uploadSuccess(user) {
      this.$store.commit("setUser", user);
    },
  },
};
</script>

<style scoped>
.mi {
  height: 50px;
  line-height: 50px;
}

.input-with-select>>>.el-input-group__prepend {
  background-color: #fff;
  color: #E6A23C;
}

.header>>>.el-card__header {
  background: rgb(67, 69, 82);
  color: rgb(233, 207, 164);
}

.avatar {
  width: 100px;
  width: 100px;
  border-radius: 50%;
}

.ll {
  width: 120px;
  text-align: right;
}

.lr {
  width: 256px;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.avatar {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #ccc;
}

.avatar-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 2px solid #f0f0f0;
}

.avatar-box:hover::after {
  background-color: rgba(0, 0, 0, .5);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  font-size: 14px;
  content: "更换图像";
}


.calendar {
  border: 3px solid #e6a13c;
  overflow: hidden;
  border-radius: 10px;
}


.calendar>>>.wh_container {
  max-width: inherit !important;
}

.calendar>>>.wh_content_item .wh_chose_day {
  background: #fff;
}

.calendar>>>.el-card__body {
  padding: 0;
}

.calendar>>>.wh_content_all {
  background-color: #fff;
}

.calendar>>>.wh_item_date,
.calendar>>>.wh_content_li,
.calendar>>>.wh_top_tag {
  color: #000;
}

.calendar>>>.wh_other_dayhide {
  color: #999;
}

.calendar>>>.wh_content_item .wh_isToday,
.calendar>>>.wh_item_date:hover {
  background: #E6A23C;
  color: #fff;
}

.calendar>>>.wh_jiantou1 {
  border-top-color: #000;
  border-left-color: #000;
}

.calendar>>>.wh_jiantou2 {
  border-top-color: #000;
  border-right-color: #000;
}
</style>
