<template>
  <div class="pte" :class="source ? source.sort : tag ? $store.getters.getSort(tag) : 'Speaking'">
    <el-card class="work" style="border-radius: 10px;width:1200px;" v-if="source">

      <div slot="header" class="flex flex-between flex-v-center color-white">

        <el-page-header @back="goBack" title="">
          <div slot="content" class="flex flex-v-center color-white">
            <div class="flex flex-v-center">
              <div class="color-white">{{ source_index + 1 }}/{{ total || allCount }}</div>
              <div class="margin-l">{{ source.tag }}</div>
              <el-tooltip class="margin-ls pointer" :content="$t('官方题型讲解')" placement="bottom">
                <i class="el-icon-info icon" @click="playVideo()"></i>
              </el-tooltip>

              <el-divider direction="vertical"></el-divider>
              <div v-if="source.title">
                <h4 class="bold" v-for="(row, index) in source.title.split('\n')" :key="index">
                  {{ row }}
                </h4>
              </div>


              <div class="flex margin-l">

                <el-tag size="small" type="success" class="margin-ls">#{{ source.no }}</el-tag>
                <!-- <el-tag size="small" v-if="source.frequency == 1" class="margin-ls" type="success">高频</el-tag> -->
                <el-tag size="small"
                  v-if="['RA', 'RS', 'DI', 'RL', 'ASQ', 'SWT', 'WE', 'FIB-RW', 'FIB-R', 'MCM-R', 'RO', 'MCS-R', 'SST', 'MCM-L', 'HCS', 'MCS-L', 'SMW', 'FIB-L', 'HIW', 'WFD'].indexOf(source.tag) != -1 && source.is_gmat == 1"
                  class="margin-ls" type="success">{{ $t('机经') }}</el-tag>
                <el-tag size="small"
                  v-if="['RS', 'RL', 'MCM-R', 'MCS-R', 'MCM-L', 'HCS', 'MCS-L', 'SMW', 'FIB-L', 'HIW'].indexOf(source.tag) != -1 && source.is_gmat == 0"
                  class="margin-ls" type="success">{{ $t('非机经') }}</el-tag>

                <el-tag size="small"
                  v-if="['RA', 'RS', 'DI', 'RL', 'ASQ', 'SWT', 'WE', 'FIB-RW', 'FIB-R', 'RO', 'SST', 'MCM-L', 'HCS', 'MCS-L', 'SMW', 'FIB-L', 'HIW', 'WFD'].indexOf(source.tag) != -1 && source.is_forecast == 1"
                  class="margin-ls" type="success">{{ $t('预测') }}</el-tag>


                <div class="flex flex-v-center"
                  v-if="['RA', 'RS', 'DI', 'RL', 'FIB-RW', 'FIB-R', 'RO', 'SST', 'WFD'].indexOf(source.tag) != -1">
                  <el-tag size="small" v-if="source.level == 2" class="margin-ls" type="success">{{ $t('难') }}</el-tag>
                  <el-tag size="small" v-else-if="source.level == 1" class="margin-ls" type="success">{{ $t('中')
                    }}</el-tag>
                  <el-tag size="small" v-else class="margin-ls" type="success">{{ $t('简') }}</el-tag>
                </div>
                <el-tag size="small" v-if="['RS', 'WFD'].indexOf(source.tag) != -1 && source.scene" class="margin-ls"
                  type="success">{{ $t(source.scene) }}</el-tag>
                <div class="flex flex-v-center" v-if="['RA', 'RS', 'DI'].indexOf(source.tag) != -1 && source.point">
                  <el-tag size="small" class="margin-ls" v-for="(p, i) in source.point" :key="i" type="success">{{ p
                    }}</el-tag>
                </div>



                <el-tag size="small" v-if="source.is_updated" class="margin-ls" type="success">{{ $t('更新') }}</el-tag>
                <el-tag v-if="['RL', 'SST'].indexOf(source.tag) != -1" class="margin-ls" size="small" type="success">{{
                  source.audio == 2
                    ? $t('近似音频') : source.audio == 1 ? $t('原音频') : $t('无音频')
                }}</el-tag>

                <el-tag size="small" v-if="['WE'].indexOf(source.tag) != -1" class="margin-ls" type="success">{{
                  source.we_type }}</el-tag>
                <el-tag size="small" v-if="source.is_new == 1" class="margin-ls" type="success">{{ $t('新题') }}</el-tag>

                <el-tag type="success" size="small"
                  v-if="(source.tag == 'RO' || source.tag == 'SWT') && source.is_original_text == 2"
                  class="margin-ls">{{ $t('仅大意') }}</el-tag>
              </div>
            </div>

          </div>
        </el-page-header>


        <div class="flex flex-v-center">
          <!-- <el-link type="success" @click="dialogVisible = true"> {{ '考过' + (source.exams_count
            >
            0 ? ('(' + source.exams_count + ')') : '') }}</el-link> -->
          <el-tag @click="dialogVisible = true" class="pointer" size="small" type="success">{{ $t('考过') +
            (source.exams_count
              >
              0 ? ('(' + source.exams_count + ')') : '') }}</el-tag>

          <el-tooltip class="margin-l pointer" effect="light" :content="$t('报错')" placement="top">
            <i class="el-icon-question icon" @click="review"></i>
          </el-tooltip>

          <el-tooltip class="margin-l pointer" effect="light" :content="$t('分享')" placement="top">
            <i class="el-icon-share icon" @click="share"></i>
          </el-tooltip>

          <el-tooltip class="margin-l pointer" effect="light" :content="$t('收藏')" placement="top">
            <div slot="content" class="flex flex-v-center">
              <el-rate v-model="stars" @change="addFavorite"></el-rate>
              <el-divider v-if="stars > 0" direction="vertical"></el-divider>
              <el-link v-if="stars > 0" @click="removeFavorite" type="primary">{{ $t('重置') }}</el-link>
            </div>

            <div class="flex flex-v-center">
              <stars :value="stars" active-color="#fff" inactive-color="#fff" color="#333"></stars>
              <!-- <i class="icon" :class="stars > 0 ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
              <span v-if="stars" style="font-size:14px;">({{ stars + $t('星') }})</span> -->
            </div>

          </el-tooltip>




          <el-dropdown v-if="favorites.length > 0" @command="addMineFavorite" class="margin-l" slot="content"
            placement="bottom">
            <i class="icon el-icon-plus pointer" style="color:#ffffff;"></i>
            <el-dropdown-menu>
              <el-dropdown-item :command="item.id" v-for="(item, index) in favorites" :key="index">
                <div class="flex flex-v-center"
                  :class="{ 'st bold': source.mine && source.mine.favorite_id == item.id }">
                  {{
                    item.title
                  }}<i v-if="source.mine && source.mine.favorite_id == item.id" class="el-icon-check"></i></div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-tooltip v-else class="margin-l pointer" :content="$t('创建收藏夹')" placement="top">
            <i class="icon el-icon-plus pointer" @click="toFa()" style="color:#ffffff;"></i>
          </el-tooltip>


          <el-popover class="margin-l" :width="290" trigger="hover">
            <div>
              <el-divider>{{ $t('个人数据') }}</el-divider>
              <div class="flex flex-v-center flex-h-center">
                <div class="center flex1">
                  <div class="st1">{{ statistic[0] }}</div>
                  <div class="st2">{{ $t('今日练习量') }}</div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div class="center flex1">
                  <div class="st1">{{ statistic[1] }}</div>
                  <div class="st2">{{ $t('今日练习时长') }}</div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div class="center flex1">
                  <div class="st1">{{ statistic[2] }}</div>
                  <div class="st2">{{ $t('获得赞数') }}</div>
                </div>
              </div>

              <div class="flex flex-v-center flex-h-center margin-t">
                <div class="center flex1">
                  <div class="st1">{{ statistic[3] }}</div>
                  <div class="st2">{{ $t('总练习量') }}</div>
                </div>
                <el-divider direction="vertical" class="margin-x"></el-divider>
                <div class="center flex1">
                  <div class="st1">{{ statistic[4] }}</div>
                  <div class="st2">{{ $t('总练习时长') }}</div>
                </div>
                <el-divider direction="vertical" class="margin-x"></el-divider>
                <div class="center flex1">
                  <div class="st1">{{ statistic[5] }}</div>
                  <div class="st2">{{ $t('总练习天数') }}</div>
                </div>
              </div>


              <el-divider>{{ $t('单词统计') }}</el-divider>

              <div class="flex flex-v-center flex-h-center">
                <div class="center">
                  <div class="st1">{{ statistic[6] || 0 }}</div>
                  <div class="st2">{{ $t('今日已背单词') }}</div>
                </div>
                <el-divider direction="vertical" class="margin-x"></el-divider>
                <div class="center">
                  <div class="st1">{{ statistic[7] || 0 }}</div>
                  <div class="st2">{{ $t('今日新加单词') }}</div>
                </div>
              </div>

            </div>
            <i slot="reference" class="el-icon-time icon pointer"></i>
            <!-- <i slot="reference" @click.stop="" class="el-icon-circle-plus-outline margin-l" style="color:#999;"></i> -->
          </el-popover>
        </div>
      </div>

      <div class="padding">
        <div class="margin-x">
          <div class="mt bold tj desc"><strong><em>{{ info.description }}</em></strong></div>
        </div>
        <div class="timer right">
          {{ timer.h > 9 ? timer.h : '0' + timer.h }}:{{ timer.m > 9 ? timer.m : '0' + timer.m }}:{{
            timer.s > 9 ?
              timer.s : '0' +
              timer.s
          }}
        </div>
        <div>


          <div class="main">
            <Speaking v-if="['RA', 'RS', 'DI', 'RL', 'ASQ'].indexOf(source.tag) >= 0" :ra_mode="ra_mode"
              :tag="source.tag" ref="holder" :source="source">
            </Speaking>

            <Writing v-if="['SWT', 'WE'].indexOf(source.tag) >= 0" :tag="source.tag" ref="holder" :source="source">
            </Writing>

            <Reading v-if="['FIB-RW', 'MCM-R', 'RO', 'FIB-R', 'MCS-R'].indexOf(source.tag) >= 0" :tag="source.tag"
              ref="holder" :source="source"></Reading>

            <Listening v-if="['SST', 'MCM-L', 'FIB-L', 'HCS', 'MCS-L', 'SMW', 'HIW', 'WFD'].indexOf(source.tag) >= 0"
              ref="holder" :source="source"></Listening>
          </div>

          <div class="flex flex-v-center flex-between">
            <div class="flex flex-v-center">

              <el-button v-if="['RS', 'DI', 'HIW', 'WFD', 'ASQ'].indexOf(source.tag) == -1" size="small"
                class="margin-l" type="warning" icon="el-icon-notebook-2" @click="$refs.wordsPanel.show()">{{
                  $t(getLabel(source.tag)) }}
              </el-button>

              <el-button size="small" v-if="source.tag == 'SST'" class="margin-l" type="warning"
                icon="el-icon-picture-outline" @click="dialogWordsVisible1 = true">{{ $t('思维导图') }}
              </el-button>

              <el-button size="small" v-if="source.tag == 'SST'" class="margin-l" type="warning"
                icon="el-icon-edit-outline" @click="showSstDialog()">{{ $t('关键词默写') }}
              </el-button>

              <el-switch v-if="source && (source.tag == 'FIB-R' || source.tag == 'FIB-RW' || source.tag == 'FIB-L' || source.tag == 'HIW' || source.tag == 'RO' || source.tag == 'MCS-R' || source.tag == 'MCM-R' || source.tag == 'MCM-L' || source.tag == 'MCS-L' || source.tag == 'HCS' || source.tag == 'SMW'
              )" class="margin-l flex0" v-model="answer_flag" @change="answerChange()" :active-value="1"
                :inactive-value="0" :active-text="$t('答案')" active-color="#E6A23C" inactive-text=""></el-switch>

              <div class="st1 margin-x" v-if="answer_flag && source && (source.tag == 'RO')">
                <div> {{ source.answer }}</div>
              </div>

              <el-button size="small" v-if="source.tag == 'RA'" class="margin-l" type="warning"
                icon="el-icon-s-opportunity" @click="$refs.imgPanel.show()">{{ $t('读法标记') }}
              </el-button>


              <el-button class="margin-l" type="warning" size="small" v-if="source.tag == 'RA'"
                @click="(popover = !popover)" icon="el-icon-video-camera">{{ $t('示范音频') }}</el-button>
              <div v-if="popover" class="margin-x">
                <wave-player width="300px" :src="this.$host + '/api/audio/' + source.no + '/Origin'"></wave-player>
              </div>



            </div>



            <div class="flex flex-v-center">
              <el-button @click="changeRaMode" size="small" v-if="source.tag == 'RA'" class="margin-l" type="warning">{{
                ra_mode ? $t('全文模式') : $t('一句话模式') }}
              </el-button>

              <el-tooltip class="item" effect="dark" :content="random ? $t('随机模式') : $t('顺序模式')" placement="top">
                <el-image class="icon-player pointer margin-x" @click="setRandom()"
                  :src="'/web/image/' + (source.sort || $store.getters.getSort(tag)) + (random ? '/random.png' : '/order.png')"></el-image>
              </el-tooltip>

              <!-- <el-checkbox v-model="random" class="margin-x st1">{{ random? '随机模式': '顺序模式' }}</el-checkbox> -->
              <el-button size="small" type="warning" @click="reDo" icon="el-icon-refresh-right">{{ $t('重做')
                }}</el-button>
              <el-button size="small" icon="el-icon-top" type="info" @click="pre()"
                :disabled="source_index == 0 && page == 1">{{ $t('上一题') }}</el-button>
              <el-button size="small" icon="el-icon-bottom" type="info" @click="next()"
                :disabled="source_index == total - 1">{{ $t('下一题') }}
              </el-button>

              <el-button v-if="['RA', 'RS', 'DI', 'RL', 'ASQ'].indexOf(source.tag) == -1" size="small" type="danger"
                icon="el-icon-position" :disabled="submitCheck()" @click="submitRecord" class="margin-l">{{
                  $t(submitText)
                }}
              </el-button>
            </div>
          </div>

          <!-- <div v-if="answer_flag && source && source.gmat_text" class="st margin-t"
          style="margin-left: 218px;">
          <div>机经点评：</div>
          <div> {{ source.gmat_text }}</div>
        </div> -->

        </div>


        <div v-if="activeName == '1'" style="position: relative;top:40px;float:right;right:20px;z-index: 100;"
          class="right"><el-switch v-model="record_flag" @change="query_record_list(1)" :active-value="0"
            :inactive-value="1" :active-text="$t('所有人')" active-color="#E6A23C" :inactive-text="$t('仅自己')"></el-switch>
        </div>

        <div v-else-if="activeName == '4'" style="position: relative;top:40px;float:right;right:20px;z-index: 100;"
          class="right"><el-switch v-model="work_record_flag" @change="query_work_record_list(1)" :active-value="0"
            :inactive-value="1" :active-text="$t('所有人')" active-color="#E6A23C" :inactive-text="$t('仅自己')"></el-switch>
        </div>
        <el-tabs v-model="activeName" @tab-click="tabClick" class="margin-lt margin-x">

          <el-tab-pane name="1">
            <span slot="label"> {{ $t('练习') }} <span class="st">{{ record_total }} </span> </span>
            <div class="padding">

              <record-list v-if="activeName == '1' && recordList.length > 0" :list="recordList" :answer="source.answer"
                :tag="source.tag"></record-list>
              <div class="center margin-t">
                <el-pagination @current-change="pageChange" background layout="prev, pager, next, jumper"
                  :total="record_total">
                </el-pagination>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="2" v-if="$store.state.user">
            <span slot="label"> {{ $t('讨论') }} <span class="st">{{ comment_total }}</span></span>

            <comment :commentList="commentList" :commentNum="comment_total"
              :avatar="$store.state.user.avatar.indexOf('http') == 0 ? $store.state.user.avatar : $host + $store.state.user.avatar"
              :authorId="-1" @doSend="sendComment" @doChidSend="sendChidComment"></comment>

            <div class="center margin-t">
              <el-pagination @current-change="pageChange" background layout="prev, pager, next, jumper"
                :total="comment_total">
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="$store.getters.typeSettings(source.tag)[0] && (($store.getters.typeSettings(source.tag)[1] && source.answer) || ($store.getters.typeSettings(source.tag)[2] && source.analysis))"
            :label="$store.getters.typeSettings(source.tag)[0]" name="3">
            <div style="line-height:20px" class="mt ls1"
              v-if="$store.getters.typeSettings(source.tag)[1] && source.answer">
              <!-- <div v-if="['RS', 'RL', 'ASQ', 'SWT', 'WE', 'SST', 'WFD'].indexOf(source.tag)">

              </div> -->

              <div @click="handleWord"
                v-html="index == 0 ? '<h4>' + $store.getters.typeSettings(source.tag)[1] + ':</h4>' + getHtml(row) : getHtml(row) ? getHtml(row) : '&nbsp;'"
                v-for="(row, index) in source.answer.split('\n')" :key="index">
              </div>
            </div>

            <div v-if="source.sense_group">
              <el-divider></el-divider>
              <div class="margin-t mt ls1" style="line-height:20px">
                <div @click="handleWord" v-for="(row, index) in source.sense_group.split('\n')"
                  v-html="index == 0 ? `<h4>${$t('意群分割版')}：</h4><div>` + getHtml(row) + '</div>' : getHtml(row) ? getHtml(row) : '&nbsp;'"
                  :key="index">
                </div>
              </div>
            </div>

            <el-divider
              v-if="$store.getters.typeSettings(source.tag)[1] && source.answer && $store.getters.typeSettings(source.tag)[2] && source.analysis"></el-divider>
            <div class="margin-t mt ls1" style="line-height:20px"
              v-if="$store.getters.typeSettings(source.tag)[2] && source.analysis">
              <div @click="handleWord" v-for="(row, index) in source.analysis.split('\n')"
                v-html="index == 0 ? '<h4>' + $store.getters.typeSettings(source.tag)[2] + '：</h4><div>' + getHtml(row, 1) + '</div>' : getHtml(row, 1) ? getHtml(row, 1) : '&nbsp;'"
                :key="index">
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane name="4" v-if="source && source.tag == 'SST'">
            <span slot="label"> {{ $t('关键词练习') }} <span class="st">{{ work_record_total }}</span></span>
            <div class="padding">
              <sst-records v-if="activeName == '4' && source" :list="workRecordList" :tag="source.tag"></sst-records>
              <div class="center margin-t">
                <el-pagination @current-change="pageChange" background layout="prev, pager, next, jumper"
                  :total="work_record_total">
                </el-pagination>
              </div>
            </div>
          </el-tab-pane>


          <el-tab-pane name="4" v-if="source && source.tag == 'DI'">
            <span slot="label"> {{ $t('参考答案') }}</span>
            <div class="padding mt">
              <div v-for="(row, index) in source.answer.split('\n')" :key="index">
                <template v-for="(words, index1) in row.split('^')">
                  <template v-for="(word, index2) in words.split(' ')">

                    <span v-if="/[\u4e00-\u9fff]/.test(word) || word.replace(/[\W\d]/g, '').length == 0"
                      :key="index1 + '_' + index2" v-html="' ' + word + ' '"></span>
                    <span style="font-size:16px" class="word" v-else :style="{ color: index1 % 2 == 1 ? 'red' : '' }"
                      v-word :key="index1 + '_' + index2" v-html="word"></span>
                  </template>
                </template>
              </div>

              <!-- <div v-html="getHtml(row)" v-for="(row, index) in source.answer.split('\n')" :key="index"></div> -->
            </div>
          </el-tab-pane>


        </el-tabs>
      </div>
    </el-card>


    <el-card style="border-radius: 10px;width:1200px;" v-else>
      <el-page-header class="color-white" slot="header" @back="goBack">
        <div slot="content" class="color-white">{{ tag.indexOf('star') == 0 ? $t('我的收藏') : (title ? $t(title) : tag) }}
        </div>
      </el-page-header>
      <el-empty></el-empty>
    </el-card>


    <!-- <div class="side-r aside" style="height:200px;">
      <el-card class="radius-l" style="width:310px">
        <el-collapse accordion value="1" @change="change1" style="border-radius: 10px;background-color: #fff;">
          <el-collapse-item title="个人数据" name="1">
            <div class="flex flex-v-center flex-h-center">
              <div class="center flex1">
                <div class="st1">{{ statistic[0] }}</div>
                <div class="st2">今日练习量</div>
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="center flex1">
                <div class="st1">{{ statistic[1] }}</div>
                <div class="st2">今日练习时长</div>
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="center flex1">
                <div class="st1">{{ statistic[2] }}</div>
                <div class="st2">获得赞数</div>
              </div>
            </div>

            <div class="flex flex-v-center flex-h-center margin-t">
              <div class="center flex1">
                <div class="st1">{{ statistic[3] }}</div>
                <div class="st2">总练习量</div>
              </div>
              <el-divider direction="vertical" class="margin-x"></el-divider>
              <div class="center flex1">
                <div class="st1">{{ statistic[4] }}</div>
                <div class="st2">总练习时长</div>
              </div>
              <el-divider direction="vertical" class="margin-x"></el-divider>
              <div class="center flex1">
                <div class="st1">{{ statistic[5] }}</div>
                <div class="st2">总练习天数</div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-card>


      <el-card class="radius-l margin-t word-static" style="width:310px">
        <el-collapse disabled accordion v-model="flag2" style="width:320px;border-radius: 10px;background-color: #fff;">
          <el-collapse-item disabled title="单词统计" name="1">
            <div class="flex flex-v-center flex-h-center">
              <div class="center">
                <div class="st1">{{ statistic[6] || 0 }}</div>
                <div class="st2">今日已背单词</div>
              </div>
              <el-divider direction="vertical" class="margin-x"></el-divider>
              <div class="center">
                <div class="st1">{{ statistic[7] || 0 }}</div>
                <div class="st2">今日新加单词</div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-card>
    </div> -->


    <div v-if="!no" class="drawer-btn" :class="drawer ? 'open' : ''" @click="drawer = !drawer"><i
        :class="drawer ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"></i></div>
    <el-drawer :visible.sync="drawer" size="65%" :with-header="false">

      <div v-if="tag == 'prediction'" class="padding prediction">
        <el-card class="header1">
          <div class="flex flex-between flex-v-center">
            <h3 class="color-white">{{ $t('海澳PTE本周高频预测') }}</h3>
            <div class="flex">
              <el-input :placeholder="$t('请输入编号/标题/关键词')" size="small" clearable v-model="filterData.search"
                @change="query(1)"></el-input>
              <el-button class="margin-x" type="primary" @click="query(1)" size="small">{{ $t('搜索') }}</el-button>
            </div>
          </div>
        </el-card>

        <el-card class="margin-t">
          <div class="flex flex-v-center">
            <el-button @click="selectSort(item.title)" round class="margin-x"
              v-for="(item, index) in $store.getters.getSorts(0)"
              :class="[{ 'active': item.title == sort }, item.title + '1']" :key="index">{{
                item.title
              }}</el-button>
          </div>

          <el-menu :default-active="prediction_tag" mode="horizontal" @select="selectType" class="margin-t">
            <el-menu-item :index="item.tag" class="margin-r" v-for="item in $store.getters.getForecastTypes(sort)"
              :key="item.tag"><span class="mt1">{{ item.tag }}({{ item.predictions_count }})</span></el-menu-item>
          </el-menu>


          <el-row class="margin-lt margin-x">
            <el-col :span="24">
              <div class="mt">{{ $t('题型信息及建议') }} - {{ prediction_tag }}</div>
              <div class="margin-t" v-if="prediction_tag == 'RA'">
                <div class="st">●{{ $t('考查技能：流利度，发音和内容') }}。</div>
                <div class="st">●{{ $t('给口语、阅读供分') }}。</div>
                <div class="st">●{{ $t('考试机经命中率一般，以练习为主，举一反三') }}。</div>
                <div class="st">●{{ $t('练习建议：练习时注意音量，语速，语调，断句，重弱读和节奏感，确保单词发音和流利度；多积累单词发音，读错的单词要建立错词本，确保发音正确') }}。</div>
              </div>

              <div class="margin-t" v-else-if="prediction_tag == 'RS'">
                <div class="st">●{{ $t('考查技能：流利度、发音、内容') }}。</div>
                <div class="st">●{{ $t('给口语、听力供分') }}。</div>
                <div class="st">●{{ $t('考试机经命中率中等，本题占分比重高，优先级别高') }}。</div>
                <div class="st">●{{ $t('练习建议：多积累机经中出现的生词以及固定搭配，在保证流利度的基础上尽可能多复述内容') }}。</div>
              </div>


              <div class="margin-t" v-else-if="prediction_tag == 'DI'">
                <div class="st">●{{ $t('考查技能：流利度、发音、内容') }}。</div>
                <div class="st">●{{ $t('给口语供分') }}。</div>
                <div class="st">●{{ $t('考试机经命中率中等偏上') }}。</div>
                <div class="st">●{{ $t('练习建议：建议把高频题库刷熟练，多积累图片出现生词；重视模板，模板能够脱口而出；积累机经中的生词以及提升图片信息抓取能力') }}。</div>
              </div>

              <div class="margin-t" v-else-if="prediction_tag == 'RL'">
                <div class="st">●{{ $t('考查技能：流利度、发音、内容') }}。</div>
                <div class="st">●{{ $t('给口语、听力供分') }}。</div>
                <div class="st">●{{ $t('考试机经命中率低') }}</div>
                <div class="st">●{{ $t('练习建议：建议把高频题库刷熟练，本题型部分原题没有原文音频，熟悉主题和关键词即可；技巧性强，熟练使用模板，举一反三') }}。</div>
              </div>

              <div class="margin-t" v-else-if="prediction_tag == 'ASQ'">
                <div class="st">●{{ $t('考查技能：流利度、发音、内容') }}。</div>
                <div class="st">●{{ $t('给口语、听力供分') }}。</div>
                <div class="st">●{{ $t('考试机经命中率中等') }}。</div>
                <div class="st">●{{ $t('练习建议：占分比较低，日常抽空积累即可，考查词汇') }}。</div>
              </div>

              <div class="margin-t" v-else-if="prediction_tag == 'SWT'">
                <div class="st">●{{ $t('考查技能：语法、词汇') }}。</div>
                <div class="st">●{{ $t('给阅读、写作供分') }}。</div>
                <div class="st">●{{ $t('考试机经命中率低') }}。</div>
                <div class="st">●{{ $t('练习建议：先刷高频, 部分原题无原文，简单熟悉原文和主旨即可，提高查找关键信息和总结的能力') }}。</div>
              </div>

              <div class="margin-t" v-else-if="prediction_tag == 'WE'">
                <div class="st">●{{ $t('考查技能：语法、词汇、拼写') }}</div>
                <div class="st">●{{ $t('给写作供分') }}。</div>
                <div class="st">●{{ $t('考试机经命中率目前百分百') }}！</div>
                <div class="st">●{{ $t('练习建议：刷高频预测，熟记10个观点句，13个支持句，及写作模板') }}。</div>
              </div>

              <div class="margin-t" v-else-if="prediction_tag == 'RO'">
                <div class="st">●{{ $t('考查技能：逻辑思维能力') }}。</div>
                <div class="st">●{{ $t('给阅读供分') }}。</div>
                <div class="st">●{{ $t('机经命中率中等') }}。</div>
                <div class="st">●{{ $t('练习建议：先刷高频，最后扩展到全题库，根据逻辑关系，时间顺序，指代关系等排列') }}。</div>
              </div>

              <div class="margin-t" v-else-if="prediction_tag == 'FIB-RW'">
                <div class="st">●{{ $t('考查技能：阅读能力，词义辨析、固定搭配、语法') }}。</div>
                <div class="st">●{{ $t('给阅读、写作供分') }}。</div>
                <div class="st">●{{ $t('机经命中率不高') }}。</div>
                <div class="st">●{{ $t('练习建议：建议先刷高频，最后扩展到全题库。以词汇语法积累为主') }}。</div>
              </div>

              <div class="margin-t" v-else-if="prediction_tag == 'FIB-R'">
                <div class="st">●{{ $t('考查技能：阅读能力，词义辨析、固定搭配、语法') }}。</div>
                <div class="st">●{{ $t('给阅读供分') }}。</div>
                <div class="st">●{{ $t('机经命中率一般') }}。</div>
                <div class="st">●{{ $t('练习建议：建议先刷高频，最后扩展到全题库') }}。</div>
              </div>

              <div class="margin-t" v-else-if="prediction_tag == 'SST'">
                <div class="st">●{{ $t('考查技能：词汇、拼写和语法') }}。</div>
                <div class="st">●{{ $t('给听力、写作供分') }}。</div>
                <div class="st">●{{ $t('机经命中率很高') }}。</div>
                <div class="st">●{{ $t('练习建议：建议先刷高频，最后扩展到全题库。逻辑相对不重要') }}。</div>
              </div>

              <div class="margin-t" v-else-if="prediction_tag == 'FIB-L'">
                <div class="st">●{{ $t('考查技能：听力、拼写和语法') }}。</div>
                <div class="st">●{{ $t('给听力、写作供分') }}。</div>
                <div class="st">●{{ $t('机经命中率低') }}</div>
                <div class="st">●{{ $t('练习建议：背诵海澳FIB-L词汇表') }}。</div>
              </div>

              <div class="margin-t" v-else-if="prediction_tag == 'HIW'">
                <div class="st">●{{ $t('考查技能：听力，词汇，手速和眼速') }}。</div>
                <div class="st">●{{ $t('给听力、阅读供分') }}。</div>
                <div class="st">●{{ $t('机经少，机经命中率低') }}</div>
                <div class="st">●{{ $t('练习建议：考试回忆难度高，机经少，以非机经练习为主。倒扣分机制，多选错选扣分，漏选不扣分') }}。</div>
              </div>


              <div class="margin-t" v-else-if="prediction_tag == 'WFD'">
                <div class="st">●{{ $t('考查技能：短期记忆力,词汇和单词拼写') }}。</div>
                <div class="st">●{{ $t('给听力、写作供分') }}。</div>
                <div class="st">●{{ $t('机经少，机经命中率极高，经常4中4，4中3，或3中3') }}</div>
                <div class="st">●{{ $t('练习建议：建议先刷高频，重点题型；平时多积累生词，利用意群记忆句子；注意单词拼写') }}。</div>
              </div>
            </el-col>
            <el-col :span="24" class="margin-t">
              <div class="mt">{{ $t('大数据预测') }}</div>
            </el-col>

            <el-col :span="24" class="flex flex-v-center">
              <div class="margin-t flex flex-between flex-v-center flex1">
                <span class="st">{{ $t('命中率') }}:</span>
                <div class="flex1 margin-x">
                  <el-progress :percentage="parseInt($store.getters.prediction(prediction_tag)[0])"
                    color="#67C23A"></el-progress>
                </div>
              </div>

              <div class="margin-t flex flex-between flex-v-center flex1">
                <span class="st">{{ $t('稳定率') }}:</span>
                <div class="flex1 margin-x">
                  <el-progress :percentage="parseInt($store.getters.prediction(prediction_tag)[1])"
                    color="#E6A23C"></el-progress>
                </div>
              </div>

              <div class="margin-t flex flex-between flex-v-center flex1">
                <span class="st">{{ $t('难度') }}:</span>
                <div class="flex1 margin-x">
                  <el-progress :percentage="parseInt($store.getters.prediction(prediction_tag)[2])"
                    color="#F56C6C"></el-progress>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="flex flex-v-center margin-t" style="margin-left:30px;">
          <el-radio-group size="small" v-model="filterData.prediction_sort" @change="query(1)">
            <!-- <el-radio-button label="">全部</el-radio-button> -->
            <el-radio-button label="预测">{{ $t('预测') }}</el-radio-button>
            <el-radio-button label="新题">{{ $t('新题') }}</el-radio-button>
            <!-- <el-radio-button label="老题重回">老题重回</el-radio-button> -->
            <el-radio-button label="更新">{{ $t('更新') }}</el-radio-button>
          </el-radio-group>


          <el-select @change="query(1)" clearable v-model="filterData.times" size="small" class="margin-x select"
            :placeholder="$t('练习次数')">
            <el-option value="不限">{{ $t('不限') }}</el-option>
            <el-option value="未练习">{{ $t('未练习') }}</el-option>
            <el-option value="少于2次">{{ $t('少于2次') }}</el-option>
          </el-select>

          <el-select v-if="prediction_tag == 'RS' || prediction_tag == 'WFD'" @change="sceneChange()" clearable
            v-model="filterData.scene" size="small" class="margin-x select" :placeholder="$t('场景分类')">
            <el-option value="不限">{{ $t('不限') }}</el-option>
            <el-option v-for="(item, index) in scenes" :key="index" :value="item">{{ $t(item) }}</el-option>
          </el-select>

          <el-select
            v-if="['ASQ', 'SWT', 'WE', 'MCS-L', 'MCM-L', 'HCS', 'SMW', 'MCS-R', 'MCM-R', 'HIW', 'FIB-L'].indexOf(prediction_tag) == -1"
            @change="query(1)" clearable v-model="filterData.level" size="small" class="margin-x select"
            :placeholder="$t('难度')">
            <el-option value="0" label="简">{{ $t('简') }}</el-option>
            <el-option value="1" label="中">{{ $t('中') }}</el-option>
            <el-option value="2" label="难">{{ $t('难') }}</el-option>
          </el-select>


          <el-select v-if="(prediction_tag == 'RS' || prediction_tag == 'WFD') && max_words_count > 5"
            @change="query(1)" clearable v-model="filterData.words_count" size="small" class="margin-x select"
            :placeholder="$t('单词数量')">
            <el-option v-for="index in (max_words_count - 5)" :value="5 + index" :label="(5 + index) + $t('词')"
              :key="index"></el-option>
          </el-select>


          <el-select v-if="info.points" @change="query(1)" clearable value-key="tag" v-model="filterData.point"
            size="small" class="margin-x select"
            :placeholder="prediction_tag == 'RS' ? $t('句子结构') : prediction_tag == 'DI' ? $t('类型') : $t('考点')">
            <el-option v-for="(point, index) in info.points" :value="point" :label="point" :key="index">
            </el-option>
          </el-select>


          <el-select v-if="prediction_tag == 'WE'" @change="query(1)" clearable v-model="filterData.we_type"
            size="small" class="margin-x select" :placeholder="$t('题目类型')">
            <el-option value="Argument" label="Argument"></el-option>
            <el-option value="Report" label="Report"></el-option>
          </el-select>

          <el-select v-if="tag.indexOf('star') == -1" @change="query(1)" clearable v-model="filterData.stars"
            size="small" class="margin-x select" :placeholder="$t('收藏')">
            <el-option :value="index" v-for="index in 5" :label="index + $t('星')" :key="index"><i
                v-for="index1 in index" style="color:rgb(247, 186, 42)" :key="index1"
                class="el-icon-star-on"></i></el-option>
          </el-select>
        </div>
      </div>

      <div v-else class="drawer">
        <div class="flex flex-between flex-v-center margin-lt">
          <h3 class="lt" v-if="tag.indexOf('star') == 0">{{ $t('我的收藏') }}</h3>
          <h3 class="lt" v-else>{{ info.title }}<el-tooltip :content="$t('官方题型讲解')" placement="bottom">
              <i class="el-icon-info pointer margin-l" @click="playVideo()"></i>
            </el-tooltip></h3>

          <el-input clearable v-model="filterData.search" @change="query(1)"
            style="width:300px;background-color: #ccc; border-radius: 20px;" :placeholder="$t('请输入编号/标题/关键词')"
            suffix-icon="el-icon-search">
          </el-input>
        </div>
        <div v-if="tag.indexOf('star') == 0" class="margin-t">
          <div class="flex flex-v-center">
            <el-button @click="selectSort1(item.title)" round class="margin-r" v-for="(item, index) in sorts"
              :class="[{ 'active': item.title == sort1 }, item.title + '1']" :key="index">{{
                item.title
              }}</el-button>
          </div>
          <el-menu v-if="sorts.length > 0" :default-active="favorite_tag" mode="horizontal" @select="selectType1"
            class="margin-t margin-ls">
            <el-menu-item :index="item.tag" class="margin-r"
              v-for="item in sorts.find((val) => { return val.title == sort1 }).types" :key="item.tag"><span
                class="mt1">{{
                  item.tag }}({{ item.counts }})</span></el-menu-item>
          </el-menu>
          <div class="line  margin-ls"></div>
        </div>
        <el-tag v-else class="margin-t mt tip" style="background-color: #e6a13c;" effect="dark"><span
            class="bold mt">TIPS：</span><span>{{
              info.tips
            }}</span></el-tag>
        <div>
          <el-menu mode="horizontal" v-if="tag.indexOf('star') == -1" :default-active="filterData.tag"
            @select="changeTag">

            <el-menu-item v-if="tag == 'RS'" index="全部" class="margin-x mt"><span class="mt1 bold">{{ $t('全部')
                }}</span></el-menu-item>

            <el-menu-item v-if="tag.indexOf('star') == -1" index="全部机经" class="margin-x mt"><span class="mt1 bold">{{
              $t('全部机经') }}</span></el-menu-item>
            <el-menu-item index="预测" class="margin-x mt bold"><span class="mt1">{{ $t('预测') }}</span></el-menu-item>


            <el-menu-item index="新题"
              v-if="['HIW', 'MCM-L', 'MCS-L', 'SMW', 'HCS', 'FIB-L', 'SST', 'MCM-R', 'MCS-R', 'RO', 'FIB-R', 'FIB-RW', 'SWT', 'WE', 'ASQ', 'RL', 'DI', 'RA', 'RS', 'WFD'].indexOf(tag) == -1"
              class="margin-x mt"><span class="mt1 bold">{{ $t('新题') }}</span></el-menu-item>
            <el-menu-item index="非机经"
              v-if="['SST', 'RO', 'FIB-R', 'FIB-RW', 'SWT', 'WE', 'ASQ', 'DI', 'RA', 'WFD'].indexOf(tag) == -1"
              class="margin-x mt"><span class="mt1 bold">{{ $t('非机经') }}</span></el-menu-item>
          </el-menu>

          <div class="flex margin-t filter margin-ls" style="flex-wrap: wrap;row-gap: 15px;column-gap: 15px;">
            <el-select @change="query(1)" clearable v-model="filterData.sort" size="small" class="select"
              placeholder="题号排序">
              <el-option value="题号排序" :label="$t('题号排序')"></el-option>
              <el-option value="新题排序" :label="$t('新题排序')"></el-option>
              <el-option value="更新排序" :label="$t('更新排序')"></el-option>
              <!-- <el-option value="场景排序" :label="$t('更新排序')"></el-option> -->
            </el-select>

            <el-select v-if="tag == 'RO'" @change="query(1)" clearable v-model="filterData.is_original_text"
              size="small" class="margin-r select" placeholder="类别">
              <el-option value="1" :label="$t('原文')">{{ $t('原文') }}</el-option>
              <el-option value="2" :label="$t('仅大意')">{{ $t('仅大意') }}</el-option>
            </el-select>

            <el-select v-if="tag == 'SST' || tag == 'RL' || favorite_tag == 'SST' || favorite_tag == 'RL'"
              @change="query(1)" clearable v-model="filterData.audio" size="small" class="select"
              :placeholder="$t('音频类型')">
              <el-option value="1" :label="$t('原音频')">{{ $t('原音频') }}</el-option>
              <el-option value="2" :label="$t('近似音频')">{{ $t('近似音频') }}</el-option>
              <el-option value="0" :label="$t('无音频')">{{ $t('无音频') }}</el-option>
            </el-select>


            <el-select @change="query(1)" clearable v-model="filterData.times" size="small" class="select"
              :placeholder="$t('练习次数')">
              <el-option value="不限" :label="$t('不限')"></el-option>
              <el-option value="未练习" :label="$t('未练习')"></el-option>
              <el-option value="少于2次" :label="$t('少于2次')"></el-option>
            </el-select>


            <el-select v-if="tag == 'RS' || tag == 'WFD'" @change="sceneChange()" clearable v-model="filterData.scene"
              size="small" class="select" :placeholder="$t('场景分类')">
              <el-option value="不限" :label="$t('不限')"></el-option>
              <el-option v-for="(item, index) in scenes" :key="index" :value="item">{{ $t(item) }}</el-option>
            </el-select>


            <el-select @change="query(1)"
              v-if="(tag.indexOf('star') == -1 && ['ASQ', 'SWT', 'WE', 'MCS-L', 'MCM-L', 'HCS', 'SMW', 'MCS-R', 'MCM-R', 'HIW', 'FIB-L'].indexOf(tag) == -1) || (tag.indexOf('star') == 0 && ['ASQ', 'SWT', 'WE', 'MCS-L', 'MCM-L', 'HCS', 'SMW', 'MCS-R', 'MCM-R', 'HIW', 'FIB-L'].indexOf(favorite_tag) == -1)"
              clearable v-model="filterData.level" size="small" class="select" :placeholder="$t('难度')">
              <el-option value="0" :label="$t('简')"></el-option>
              <el-option value="1" :label="$t('中')"></el-option>
              <el-option value="2" :label="$t('难')"></el-option>
            </el-select>


            <el-select
              v-if="(tag == 'RS' || favorite_tag == 'RS' || tag == 'WFD' || favorite_tag == 'WFD') && max_words_count > 5"
              @change="query(1)" clearable v-model="filterData.words_count" size="small" class="select"
              :placeholder="$t('单词数量')">
              <el-option v-for="index in (max_words_count - 5)" :value="5 + index" :label="(5 + index) + $t('词')"
                :key="index"></el-option>

            </el-select>


            <el-select v-if="info.points" @change="query(1)" clearable value-key="tag" v-model="filterData.point"
              size="small" class="select"
              :placeholder="(info.tag == 'RS' || favorite_tag == 'RS') ? $t('句子结构') : (info.tag == 'DI' || favorite_tag == 'DI') ? $t('类型') : $t('考点')">
              <el-option v-for="(point, index) in info.points" :value="point" :label="point" :key="index">
              </el-option>
            </el-select>


            <el-select v-if="tag.indexOf('star') == -1" @change="query(1)" clearable v-model="filterData.stars"
              size="small" class="select" :placeholder="$t('收藏')">

              <el-option :value="index" v-for="index in 5" :label="index + $t('星')" :key="index"><i
                  v-for="index1 in index" style="color:rgb(247, 186, 42)" :key="index1"
                  class="el-icon-star-on"></i></el-option>

              <el-option :value="-1" :label="$t('未收藏')"></el-option>
            </el-select>


            <el-select v-if="tag.indexOf('favorite') == -1 && favorites.length > 0" @change="query(1)" clearable
              v-model="filterData.favorite_id" size="small" class="select" :placeholder="$t('收藏夹')">
              <el-option :value="row.id" v-for="(row, index) in favorites" :key="index" :label="$t(row.title)">{{
                $t(row.title) }}</el-option>
            </el-select>

            <el-select v-if="tag == 'WE'" @change="query(1)" clearable v-model="filterData.we_type" size="small"
              class="select" :placeholder="$t('题目类型')">
              <el-option value="Argument" label="Argument"></el-option>
              <el-option value="Report" label="Report"></el-option>
            </el-select>
          </div>

        </div>
      </div>

      <div class="drawer">
        <div class="mt padding flex flex-between flex-v-center">

          <span v-if="tag == 'prediction'">{{ $t('筛选出') }}{{ total }}{{ $t('题，已练') }}{{ allCount }}{{ $t('题') }}。</span>
          <span v-else> {{ $t('题库共') }}{{ allCount }}{{ $t('道题') }}，{{ $t('筛选出') }}{{ total }}{{ $t('道题') }}</span>

          <div class="flex flex-v-center">
            <el-button v-if="tag.indexOf('star') === 0" class="margin-r" style="font-size:20px;font-weight: bold;"
              icon="el-icon-delete" @click="reset1" size="small" circle type="primary"></el-button>
            <el-tooltip v-if="!isReseting" class="margin-ls pointer" :content="$t('重置练习记录')" placement="top">
              <el-button style="font-size:20px;font-weight: bold;" icon="el-icon-refresh-right" @click="setReset()"
                size="small" circle type="primary"></el-button>
            </el-tooltip>

            <div>
              <el-checkbox @change="chooseAll" class="margin-r" v-if="isReseting">全选</el-checkbox>
            </div>
            <el-button v-if="isReseting" round type="primary" @click="reset()" icon="close">重置</el-button>
            <el-button @click="isReseting = false" v-if="isReseting" plain round type="primary">取消</el-button>
          </div>
        </div>
        <div v-loading="loading0">
          <el-checkbox-group v-model="ids">
            <div class="pointer" v-for="(item, index) in list" :key="index" @click="choose(index)">
              <div class="flex flex-between padding-s">
                <div class="flex flex-v-center st1" :class="source && source.id == item.id ? 'on' : ''">
                  <div v-if="isReseting"><el-checkbox :label="item.id">{{ }}</el-checkbox></div>
                  <div>{{ (page - 1) * pageSize + index + 1 }}.</div>
                  <div class="margin-l">{{ item.title }}</div>

                  <div class="flex flex-v-center">
                    <el-tag type="primary" effect="dark" size="small" class="margin-ls">#{{ item.no }}</el-tag>
                    <!-- <el-tag effect="dark" size="small" v-if="item.frequency == 1" class="margin-ls"
                    type="primary">高频</el-tag> -->
                    <el-tag effect="dark" size="small"
                      v-if="['WE'].indexOf(tag) == -1 && item.is_gmat && (filterData.tag != '预测' && filterData.tag != '全部机经')"
                      class="margin-ls" type="primary">{{ $t('机经') }}</el-tag>

                    <el-tag effect="dark" style="background-color: rgba(233,169,70,0.7) !important;color:rgb(97,61,10)"
                      size="small" v-if="item.is_forecast && filterData.tag != '预测'" class="margin-ls" type="primary">{{
                        $t('预测') }}</el-tag>

                    <div class="flex flex-v-center"
                      v-if="['HIW', 'FIB-L', 'SWT', 'WE', 'MCM-L', 'HCS', 'MCS-L', 'SMW', 'MCM-R', 'MCS-R', 'ASQ'].indexOf(tag) == -1">
                      <el-tag effect="dark"
                        style="background-color: rgba(255,226,75,0.6) !important; color:rgb(240,105,0);" size="small"
                        v-if="item.level == 1" class="margin-ls" type="primary">{{ $t('中') }}</el-tag>
                      <el-tag effect="dark" style="background-color: rgba(255,119,48,0.6) !important;color:rgb(71,0,0)"
                        size="small" v-else-if="item.level == 2" class="margin-ls" type="primary">{{ $t('难') }}</el-tag>
                      <el-tag effect="dark" style="background-color: rgba(234,255,80,0.6) !important;color:rgb(3,70,0)"
                        size="small" v-else class="margin-ls" type="primary">{{ $t('简') }}</el-tag>
                    </div>

                    <el-tag effect="dark" style="background-color: rgba(161,194,120,0.4) !important;color:rgb(0,46,16)"
                      size="small" v-if="(item.tag == 'RO' || item.tag == 'SWT') && item.is_original_text == 2"
                      class="margin-ls" type="primary">{{
                        $t('仅大意') }}</el-tag>

                    <el-tag effect="dark"
                      style="background-color: rgba(116,162,112,0.4) !important;color:rgb(40,127,46)" size="small"
                      v-if="['RL', 'SST'].indexOf(tag) != -1 && item.audio == 2" class="margin-ls" type="primary">{{
                        $t('近似音频') }}</el-tag>

                    <el-tag effect="dark"
                      style="background-color: rgba(213,122,112,0.4) !important;color:rgb(133,54,199)" size="small"
                      v-if="['RL', 'SST'].indexOf(tag) != -1 && item.audio == 1" class="margin-ls" type="primary">{{
                        $t('原音频') }}</el-tag>

                    <el-tag effect="dark" style="background-color: rgba(0,21,99,0.4) !important;color:rgb(38,38,38)"
                      size="small" v-if="['RL', 'SST'].indexOf(tag) != -1 && item.audio == 0" class="margin-ls"
                      type="primary">{{ $t('无音频') }}</el-tag>

                    <el-tag effect="dark" size="small" v-if="item.is_new" class="margin-ls" type="primary"
                      style="background-color: rgba(134,143,210,0.6) !important;color:rgb(42,32,102)">{{ $t('新题')
                      }}</el-tag>


                    <el-tag effect="dark" size="small" v-if="item.is_updated" class="margin-ls" type="primary"
                      style="background-color: #dce685 !important;color:rgb(42,32,102)">{{ $t('更新') }}</el-tag>

                  </div>


                </div>

                <div class="flex flex-v-center">
                  <div class="margin-l" v-if="item.stars">
                    <i v-for="index1 in item.stars" style="color:rgb(247, 186, 42)" :key="'s' + index1"
                      class="el-icon-star-on"></i>
                  </div>
                  <el-tag v-if="item.records_count"
                    style="background-color: rgba(67,175,167,0.6);color:rgb(25, 85, 116);" type="success"
                    class="margin-l">{{ $t('已练') }}{{ item.records_count }}{{ $t('次') }}</el-tag>
                  <el-tag v-else type="success" style="background-color: rgba(136,132,121 ,0.6);color:rgb(33, 33, 33);"
                    class="margin-l">{{ $t('未练习') }}</el-tag>

                  <el-popover :width="160" trigger="hover">
                    <div class="flex flex-v-center flex-h-center">
                      <!-- <span class="st">{{ $t('收藏') }}：</span> -->
                      <el-rate v-model="item.stars" @change="addFavorite1(item)"></el-rate>
                      <div v-if="item.stars > 0">
                        <el-divider direction="vertical"></el-divider>
                        <el-link icon="el-icon-close" style="color:#e6a13c" class="icon"
                          @click="removeFavorite1(item, index)"></el-link>
                      </div>
                    </div>

                    <stars slot="reference" :value="item.stars"></stars>
                    <!-- <i slot="reference" style="position: relative;width:40px;" @click.stop=""
                      :class="item.stars ? 'el-icon-star-on' : 'el-icon-star-off'"
                      class="icon margin-l flex flex-h-center flex-v-center"
                      :style="{ color: item.stars ? '#e6a13c' : '#999', fontSize: item.stars ? '30px' : '22px' }"><span
                        style="font-size: 12px;position: absolute;color: #fff;" v-if="item.stars">{{ item.stars }}</span></i> -->
                  </el-popover>
                  <el-dropdown v-if="favorites.length > 0" @command="addMineFavorite" class="margin-l" slot="content"
                    placement="bottom">
                    <i class="el-icon-plus icon" style="color:#999;"></i>
                    <el-dropdown-menu>
                      <el-dropdown-item :command="item1.id + ',' + item.id" v-for="(item1, index) in favorites"
                        :key="index">
                        <div class="flex flex-v-center"
                          :class="{ 'st bold': item.mine && item.mine.favorite_id == item1.id }">
                          {{
                            item1.title
                          }}<i v-if="item.mine && item.mine.favorite_id == item1.id" class="el-icon-check"></i></div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <el-tooltip v-else class="margin-l pointer" :content="$t('创建收藏夹')" placement="top">
                    <i class="icon el-icon-plus pointer" @click="toFa()" style="color:#ffffff;"></i>
                  </el-tooltip>


                </div>
              </div>
              <div class="line"></div>
            </div>
          </el-checkbox-group>
        </div>

        <div class="center margin-t">
          <el-pagination :current-page.sync="page" :page-size="pageSize" @current-change="pageChange0" background
            layout="total,prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>


      </div>
    </el-drawer>


    <words-resize-panel ref="wordsPanel" :source="source">
    </words-resize-panel>

    <img-resize-panel ref="imgPanel" :source="source">
    </img-resize-panel>


    <el-dialog class="drag" :modal="false" v-drag :visible.sync="dialogWordsVisible1" width="40%">
      <h4 slot="title">{{ $t('思维导图') }}</h4>
      <div class="line"></div>
      <div class="mt">
        <el-image :src="imageUrl1()" :preview-src-list="[imageUrl1()]"></el-image>
      </div>
      <div class="right margin-t">
        <el-button round type="primary" @click="dialogWordsVisible1 = false">{{ $t('关闭') }}</el-button>
      </div>
    </el-dialog>




    <el-dialog title="" :visible.sync="dialogVisible" top="15%" width="500px" center>
      <h4 slot="title">{{ $t('添加您的考试信息') }}</h4>

      <el-tabs v-model="activeName1">
        <el-tab-pane :label="$t('考试信息')" name="1">
          <el-form ref="examForm" size="small" :model="examForm" label-width="100px">
            <el-form-item prop="date" :label="$t('考试日期') + ':'"
              :rules="[{ required: true, message: $t('请选择考试日期'), trigger: 'blur' },]">
              <el-date-picker value-format="yyyy-MM-dd" v-model="examForm.date" type="date" :placeholder="$t('选择日期')">
              </el-date-picker>
            </el-form-item>

            <el-form-item :label="$t('考试回忆') + ':'">
              <el-input type="textarea" :rows="3" v-model="examForm.content" :placeholder="$t('请输入您的考试回忆')"></el-input>
            </el-form-item>
          </el-form>

          <div class="center">
            <el-button round style="width:200px" type="primary" @click="examedSubmit">{{ $t('提交') }}</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('我的记录')" name="2">
          <el-table :data="examList" style="width: 100%">
            <el-table-column prop="date" :label="$t('考试日期')">
            </el-table-column>
            <el-table-column prop="content" :label="$t('考试回忆')">
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>






    <word-dialog ref="wordDialog" :type="1"></word-dialog>
    <share-dialog ref="shareDialog"></share-dialog>
    <review-dialog ref="reviewDialog"></review-dialog>
    <evaluate-dialog ref="evaluateDialog" @ended="evaluateEnd"></evaluate-dialog>
    <score-dialog ref="scoreDialog"></score-dialog>
    <vip-alert ref="vipalert"></vip-alert>
    <video-dialog ref="videoDialog"></video-dialog>
    <sst-dialog ref="sstDialog" @submited="query_work_record_list(1)"></sst-dialog>

  </div>
</template>
<script>
import moment from 'moment';
// import { mapState } from 'vuex'
import comment from '../../components/comment/Comment.vue'
import Speaking from '../../components/Speaking.vue'
import Writing from '../../components/Writing.vue'
import Reading from '../../components/Reading.vue'
import Listening from '../../components/Listening.vue'
import recordList from '../../components/RecordList.vue'
import wordDialog from '../../components/WordDialog.vue'
import shareDialog from '../../components/ShareDialog.vue'
import reviewDialog from '../../components/ReviewDialog.vue'

import WavePlayer from '../../components/WavePlayer.vue'
import drag from "../../components/drag";
import wordsResizePanel from '../../components/WordsResizePanel.vue'
import imgResizePanel from '../../components/ImgResizePanel.vue'
import evaluateDialog from '../../components/EvaluateDialog.vue'
import scoreDialog from '../../components/ScoreDialog.vue'
import vipAlert from '../../components/VipAlert.vue'
import videoDialog from '../../components/VideoDialog.vue'
import sstDialog from '../../components/SstDialog.vue'
import sstRecords from '../../components/SstRecords.vue'
import stars from '../../components/Stars.vue'

export default {
  directives: {
    drag
  },
  components: {
    stars, sstRecords, sstDialog, videoDialog, vipAlert, scoreDialog, evaluateDialog, imgResizePanel, wordsResizePanel, recordList, comment, Speaking, Writing, Reading, Listening, wordDialog, shareDialog, reviewDialog, WavePlayer
  },

  data() {
    return {
      title: '',
      ids: [],
      isReseting: false,
      loading0: false,
      answer_flag: 0,
      random: false,
      popover: false,
      stars: 0,
      statistic: [],
      total_time: 0,
      drawer: true,
      filterData: {
        tag: "全部机经",
        audio: "",
        sort: "",
        times: "",
        level: "",
        point: "",
        words_count: "",
        search: "",
        prediction_sort: "预测"
      },
      page: 1,
      source: { tag: '', words_en: "", words_zh: "", stars: 0, exams_count: 0 },
      activeName: "0",
      lastActiveName: "0",
      activeName1: "1",
      timer: {
        h: 0,
        m: 0,
        s: 0
      },
      tag: '',
      no: '',
      type: '',
      flag: 0,
      info: {},
      list: [],
      total: 0,
      allCount: 0,
      recordCount: 0,
      dialogWordsVisible: false,
      dialogWordsVisible1: false,
      reviewList: [],
      comment_page: 1,
      comment_total: 0,
      commentList: [],
      examList: [],
      record_page: 1,
      recordList: [],
      record_flag: 1,
      record_total: 0,

      work_record_page: 0,
      work_record_total: 0,
      workRecord_total: 0,
      workRecordList: [],
      work_record_flag: 1,

      content: null,
      examForm: {
        date: "",
        content: ""
      },
      dialogVisible: false,
      source_index: 0,
      submitText: "提交",
      max_words_count: 0,
      back: 0,
      sort: "Speaking",
      prediction_tag: "RA",
      recordId: 0,
      pageSize: 20,

      favorite_tag: "RA",
      sorts: [],
      sort1: "Speaking",

      ra_mode: false,//一句话模式
      ra_content: null,
      flag2: "1",
      scenes: [],
      favorites: []
    };
  },


  watch: {
    $route(route) {
      this.tag = route.query.tag || "";
      if (this.tag) {
        this.no = "";
        this.drawer = true;
      }
      this.resetParam();

      this.page = 1;
      this.source_index = 0;
      if (this.source) this.source.id = 0;

      if (this.tag == "MCM-R" || this.tag == "MCS-R") {
        this.filterData.tag = "非机经";
      }
      this.query();
    },

    source_index(val) {
      if (this.tag) {
        localStorage.setItem(this.tag + "_source_index", val)
      }
    },

    tag(val) {
      if (val == 'RS' || val == 'WFD') {
        this.$http.post("/api/source/words/count", { tag: val }).then((count) => {
          this.max_words_count = count;
        })

        this.$http.post("/api/source/scenes", { tag: val }).then((scenes) => {
          this.scenes = scenes
        })
      }
    },

    favorite_tag(val) {
      if (val == 'RS' || val == 'WFD') {
        this.$http.post("/api/source/words/count", { tag: val }).then((count) => {
          this.max_words_count = count;
        })

        this.$http.post("/api/source/scenes", { tag: val }).then((scenes) => {
          this.scenes = scenes
        })
      }
    },

    prediction_tag(val) {
      if (val == 'RS' || val == 'WFD') {
        this.$http.post("/api/source/words/count", { tag: val }).then((count) => {
          this.max_words_count = count;
        })

        this.$http.post("/api/source/scenes", { tag: val }).then((scenes) => {
          this.scenes = scenes
        })
      }
    },


    source(val) {
      if (val) {
        this.content = null;
        this.popover = false;
        this.info = this.$store.getters.getType(val.tag);
        this.stars = val.favorite ? val.favorite.stars : 0;
        this.record_flag = 1;
        this.query_record_list(1);
        this.query_comment_list(1);
        this.query_exam_list(1);

        if (this.source.tag == "SST") {
          this.query_work_record_list(1)
        }

        this.timer.h = 0;
        this.timer.m = 0;
        this.timer.s = 0;
        this.submitText = "提交";
        this.activeName = "0";
        this.answer_flag = false;
        this.answerChange();

        if (["SWT", "WE", "SST"].indexOf(this.source.tag) != -1) {
          this.source.answer += (" (" + this.source.answer.split(" ").length + " words)")
        }

        if (this.$refs.wordsPanel) {
          this.$refs.wordsPanel.hide()
        }
      }
    },
  },

  created() {
    this.$bus.$emit("aside", false);
    this.$bus.$emit("full", true);
    this.$bus.$on("word", (word) => {
      if (this.ra_mode) return
      this.showWord(word);
    })


    this.$bus.$on("ra_content", (content) => {
      this.ra_content = content;
    })



    this.$bus.$on("content", (content) => {
      console.log(content);

      if (this.source.tag == "SST" && content) {
        this.content = content[0];
      } else {
        this.content = content;
      }

    })

    this.tag = this.$route.query.tag;
    this.no = this.$route.query.no || "";
    this.type = this.$route.query.type || "";
    this.back = this.$route.query.back || 0;

    if (this.tag == "prediction") {
      this.$http.post("/api/source/words/count", { tag: "RS" }).then((count) => {
        this.max_words_count = count;
      })
    }

    if (this.tag.indexOf("star") == 0) {
      this.filterData.tag = "";
      let stars = parseInt(this.tag.replace("star", ""));
      this.$http.post("/api/source/favorite/sorts", { type: 0, stars: stars }).then((sorts) => {
        this.sorts = sorts;
      })
    }



    if (this.no) {
      this.drawer = false;
    }

    if (this.tag && !this.no) {

      let sort1 = localStorage.getItem(this.tag + "_sort1");
      this.sort1 = sort1 || "Speaking";


      let source_index = localStorage.getItem(this.tag + "_source_index");
      this.source_index = parseInt(source_index) || 0;

      let last_param = localStorage.getItem(this.tag + "_last_param");

      if (last_param) {
        let param = JSON.parse(last_param);
        this.page = param.page;
        this.tag = param.tag;
        this.type = param.type;
        this.no = param.no;
        this.favorite_tag = param.favorite_tag;
        this.prediction_tag = param.prediction_tag;
        this.filterData = param.filter;
      }
    }

    if (this.tag == "MCM-R" || this.tag == "MCS-R") {
      this.filterData.tag = "非机经";
    }

    if (this.tag == "RS" || this.tag == "WFD") {
      this.filterData.tag = "预测";
    }
    this.query();
    this.query_statistic();
  },
  mounted() {
    this.timeJob();
    this.query_favorites()
  },


  beforeDestroy() {
    this.$http.post("/api/source/time", { time: this.total_time })
  },


  methods: {

    toFa() {
      this.$router.push('/main/favorites')
    },

    setReset() {
      this.isReseting = !this.isReseting
      this.ids = []
    },

    chooseAll(val) {
      let ids = []
      if (val) {
        this.list.forEach(item => {
          ids.push(item.id)
        })
      }
      this.ids = ids
    },
    addMineFavorite(id) {
      let source_id = 0
      let favorite_id = 0
      if (id.toString().indexOf(',') != -1) {
        let items = id.split(",");
        favorite_id = items[0]
        source_id = items[1];
      } else {
        favorite_id = id;
        source_id = this.source.id
      }
      this.$http.post("/api/source/mine", { favorite_id: favorite_id, source_id: source_id }).then(() => {
        let source = null;
        let index = -1;
        if (id.toString().indexOf(',') != -1) {

          index = this.list.findIndex((val) => {
            return val.id == source_id
          })
          source = this.list[index]
        } else {
          source = this.source
        }
        if (source.mine) {
          source.mine.favorite_id = favorite_id;
        } else {
          source.mine = { favorite_id: favorite_id }
        }
        if (index != -1) {
          this.$set(this.list, index, source)
        }
        this.$message.success(this.$t("添加成功"))
      })
    },
    query_favorites() {
      this.$http.post("/api/source/mine/favorite/list").then((favorites) => {
        this.favorites = favorites;
      })
    },

    sceneChange() {
      if (this.$store.getters.getRole() == 0) {
        this.$vip_alert("此功能");
        this.$nextTick(() => {
          delete this.filterData['scene']
        })
      } else {
        this.query(1)
      }
    },

    querySstRecord() {
      this.activeName = "4";
      this.query_work_record_list(1);
    },
    showSstDialog() {
      if (this.$store.getters.getRole() == 0) {
        this.$vip_alert("此功能");
        return
      }
      // this.$bus.$emit("stop-player")
      this.$refs.sstDialog.show(this.source);
    },

    showWord(word) {
      word = word.replace(/(^\W*)|(\W*$)/g, "");
      if (word) {
        word = word.trim().toLowerCase();
        this.$refs.wordDialog.show(word, this.source.tag);
      }
    },

    handleWord(e) {
      this.showWord(e.target.id)
    },
    change1(e) {
      this.flag2 = e
    },
    playVideo() {
      this.$refs.videoDialog.show(this.info.tag + "-官方题型讲解", this.$host + this.info.video)
    },
    query_statistic() {
      this.$http.post("/api/source/statistic").then((res) => {
        this.statistic = res
      })
    },

    getHtml(row, flag) {
      if (!row) return "<br>";
      if (flag && this.source.tag != "ASQ" && this.source.tag != "FIB-RW" && this.source.tag != "FIB-R") return row;
      let html = `<div class="flex flex-wrap">`;
      if (this.source.tag == "FIB-RW" || this.source.tag == "FIB-R") {
        html = "<div>";
      }
      let parts = row.split("^");
      parts.forEach((item, index) => {
        if (index % 2 == 1) {
          if (this.source.tag == 'FIB-RW' || this.source.tag == 'FIB-R') {
            html += `<span  style="font-weight:600;text-decoration:underline;">${item}</span>`
          } else if (['RS', 'RL', 'ASQ', 'SWT', 'WE', 'SST', 'WFD', 'DI'].indexOf(this.source.tag) != -1) {
            let words = item.split(" ");
            words.forEach((word) => {

              if (word.replace(/\W/g, "")) {
                html += `<span id="${word}" style="color:red;cursor: pointer;" class="word5">${word}</span>`
              } else {
                if (word == ',') {
                  html += `<span style="color:red;">${word}</span>`
                } else {
                  html += `<span style="color:red;" class="word4">${word}</span>`
                }
              }
            })
          } else {
            html += `<span style="color:red">${item}</span>`
          }
        } else {
          if (['RS', 'RL', 'ASQ', 'SWT', 'WE', 'SST', 'WFD'].indexOf(this.source.tag) != -1) {
            let words = item.split(" ");
            words.forEach((word) => {
              if (word.replace(/\W/g, "")) {
                html += `<span id="${word}" style="cursor: pointer;" class="word5">${word}</span>`
              } else {
                if (word == ',') {
                  html += `<span>${word}</span>`
                } else {
                  html += `<span class="word4">${word}</span>`
                }

              }
            })
          } else {
            html += `<span>${item}</span>`
          }
        }
      })
      html += "</div>";
      return html
    },
    changeRaMode() {
      this.ra_mode = !this.ra_mode;
      // this.$bus.$emit("ra_mode", this.ra_mode);
    },
    resetParam() {
      this.filterData = {
        tag: "全部机经",
        audio: "",
        sort: "",
        times: "",
        level: "",
        point: "",
        words_count: "",
        search: "",
        prediction_sort: "",
      };
    },
    evaluateEnd() {
      this.$refs.scoreDialog.show(this.recordId);
    },
    submitCheck() {
      if (['FIB-L', 'FIB-R', 'FIB-RW'].indexOf(this.source.tag) != -1) {
        return false;
      } else {
        return this.content == null || this.content.length == 0 || this.submitText != '提交';
      }
    },

    setRandom() {
      this.random = !this.random;
      this.query();
    },

    getLabel(tag) {
      let label = "中英对照";
      if (tag == "RL") {
        label = "原文翻译";
      } else if (tag == "SWT") {
        label = "关键句标记";
      } else if (tag == "WE") {
        label = "题目翻译";
      } else if (tag == "FIB-RW" || tag == "MCM-R" || tag == "RO" || tag == "FIB-R" || tag == "MCS-R") {
        label = "查看翻译";
      } else if (tag == "SST") {
        label = "查看原文";
      } else if (tag == "MCM-L" || tag == "MCS-L" || tag == "SMW" || tag == "HCS") {
        label = "音频文本";
      }
      return label;
    },
    selectSort(sort) {
      this.sort = sort;
      this.$nextTick(() => {
        this.prediction_tag = this.$store.getters.getTypes(sort)[0].tag;
        this.query(1)
      })
    },

    selectSort1(sort) {
      this.sort1 = sort;
      this.filterData.tag = "";
      this.$nextTick(() => {
        this.favorite_tag = this.sorts.find((val) => { return val.title == sort }).types[0].tag;
        this.query(1)
      })
    },

    selectType1(tag) {
      this.favorite_tag = tag;
      this.filterData.tag = "";
      this.info = this.$store.getters.getType(tag);
      this.query(1)
    },
    changePrediction() {
      if (this.prediction_flag == '预测') {
        this.filterData.tag = "预测"
      } else if (this.prediction_flag == '按题号') {
        this.filterData.tag = ""
      } else if (this.prediction_flag == '新题') {
        this.filterData.tag = "新题"
      } else if (this.prediction_flag == '老题重回') {
        this.filterData.tag = "老题重回"
      } else if (this.prediction_flag == '更新') {
        this.filterData.tag = "更新"
      }
    },
    selectType(type) {
      this.prediction_tag = type;
      this.info = this.$store.getters.getType(type);
      this.query(1)
    },



    pageChange0(page) {
      this.page = page;
      // this.source_index = (this.page - 1) * this.pageSize;
      this.query();
    },

    resize() {

    },

    answerChange() {
      this.$bus.$emit("view_answer", this.answer_flag);
      this.$bus.$emit("submit", this.answer_flag);
    },

    imageUrl() {
      return this.$host + '/upload/图片/RA读法标记/' + this.source.no + '.png?v=' + (moment().format('MMDDHHmm'))
    },

    imageUrl1() {
      if (this.source) {
        return this.$host + '/upload/图片/SST思维导图/' + this.source.no + '.png?v=' + (moment().format('MMDDHHmm'))
      }
      return null;
    },

    reset() {
      let tip = "是否重置(所有)练习记录"
      if (this.ids.length > 0) {
        tip = "是否重置(已选中)练习记录"
      }
      this.$confirm(this.$t(tip) + "?", this.$t('提示'), {
        // center: true,
        customClass: "reset",
        confirmButtonText: this.$t('确定'),
        confirmButtonClass: "confirm-btn",
        cancelButtonText: this.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/source/record/clear", { tag: this.tag, ids: this.ids }).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('重置成功') + '!'
          });

          this.list.forEach(item => {
            if (this.ids.length == 0 || this.ids.indexOf(item.id) != -1) {
              item.records_count = 0;
            }
          })
          this.setReset()
        })
      }).catch(() => {

      });
    },


    reset1() {
      this.$confirm(this.$t('删除后所有记录将会清空,确认删除此收藏记录') + '?', this.$t('提示'), {
        customClass: "reset",
        confirmButtonText: this.$t('确定'),
        confirmButtonClass: "confirm-btn",
        cancelButtonText: this.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/source/favorite/remove", { stars: this.tag.replace('star', ''), tag: this.source.tag }).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('重置成功') + '!'
          });

          let stars = parseInt(this.tag.replace("star", ""));
          this.$http.post("/api/source/favorite/sorts", { type: 0, stars: stars }).then((sorts) => {
            this.sorts = sorts;
          })
          this.source = null;
          this.query()
        })
      }).catch(() => {

      });
    },

    pageChange(page) {
      this.query_record_list(page);
    },

    review() {
      this.$refs.reviewDialog.show(this.source.id);
    },

    share() {
      this.$refs.shareDialog.show("分享题目", window.location.href + "&id=" + this.source.id, "复制链接，向小伙伴分享此题目！");
    },

    changeTag(index) {
      this.filterData.tag = index;
      // if (this.source) this.source.id = 0;
      // this.source_index = 0;
      this.query(1, true);
    },

    addFavorite(stars) {
      this.$http.post("/api/source/favorite", { source_id: this.source.id, stars: stars }).then((info) => {
        this.source.favorite = info;
        this.$message({
          message: this.$t('收藏成功'),
          type: 'success'
        });
      });
    },

    addFavorite1(item) {
      this.$http.post("/api/source/favorite", { source_id: item.id, stars: item.stars }).then(() => {
        this.$message({
          message: this.$t('收藏成功'),
          type: 'success'
        });
      });
    },

    removeFavorite1(item) {
      this.$http.post("/api/source/favorite/remove", { source_id: item.id }).then(() => {
        item.stars = 0;
      });
    },

    removeFavorite() {
      this.$http.post("/api/source/favorite/remove", { source_id: this.source.id }).then(() => {
        this.source.favorite = null;
        this.stars = 0;
      });
    },


    async submitRecord() {
      if (this.submitText != "提交") return;
      this.submitText = "提交中...";
      this.$bus.$emit("loading", true);
      if (['SWT', 'WE', 'SST'].indexOf(this.source.tag) != -1) {
        if (this.$store.getters.getRole() == 0) {
          let flag = await this.getTimes();
          if (flag == 0) {
            this.submitText = "提交";
            this.$bus.$emit("loading", false);
            return;
          }
        }
      }

      if (this.content instanceof Blob) {
        let formData = new FormData();
        formData.append("file", this.content);
        formData.append("source_id", this.source.id);

        if (this.ra_mode) {
          formData.append("ra_content", this.ra_content);
        }

        this.$http.post("/api/source/record", formData, {
          'Content-type': 'multipart/form-data'
        }).then(() => {
          this.submitText = "已提交"
          this.$bus.$emit("loading", false);
          this.$message({
            message: this.$t('提交成功'),
            type: 'success'
          })

          this.query_record_list();
          this.list[this.source_index % 20].records_count += 1;
          this.activeName = "1";
        }).catch(() => {
          this.submitText = "提交"
          this.$bus.$emit("loading", false);
        });
      } else {
        this.$http.post("/api/source/record", { source_id: this.source.id, content: this.content ? this.content.toString() : '' }).then((result) => {
          this.recordId = result.id;
          this.$bus.$emit("submit", 1, result);
          this.content = null;
          this.submitText = "已提交"
          this.$bus.$emit("loading", false);
          this.$message({
            message: this.$t('提交成功'),
            type: 'success'
          });

          if (['SWT', 'WE', 'SST'].indexOf(this.source.tag) != -1) {
            this.$refs.evaluateDialog.hide();
            this.$nextTick(() => {
              this.evaluateEnd();
            })
          }
          this.list[this.source_index % 20].records_count += 1;
          this.query_record_list();
          this.activeName = "1";
        })
      }
    },

    getTimes() {
      return new Promise((resolve) => {
        this.$refs.vipalert.show((flag) => {
          resolve(flag)
        })
      })
    },


    timeJob() {
      let that = this;
      this.total_time++;
      setTimeout(() => {
        if (that.timer.s == 59) {
          if (that.timer.m == 59) {
            that.timer.h += 1;
            that.timer.m = 0
          } else {
            that.timer.m += 1;
            that.timer.s = 0;
          }
        } else {
          that.timer.s += 1;
        }
        that.timeJob();
      }, 1000)
    },

    tabClick() {
      if (this.activeName == 3 && this.source.tag == "RO" && this.$store.getters.getRole() == 0) {
        this.$nextTick(() => {
          this.activeName = this.lastActiveName;
        })
        this.$vip_alert("此功能");
        return
      } else if (this.activeName == 4 && (this.source.tag == "SST" || this.source.tag == "DI") && this.$store.getters.getRole() == 0) {
        this.$nextTick(() => {
          this.activeName = this.lastActiveName;
        })
        this.$vip_alert("此功能");
        return
      }


      if (this.activeName == this.lastActiveName) {
        this.activeName = "0";
        this.lastActiveName = "0";
      } else {
        this.lastActiveName = this.activeName;
      }
    },


    personal() {
      this.$confirm(this.$t('请先完善您的用户信息'), this.$t('提示'), {
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$router.push("/main/personal")
      }).catch(() => {

      });
    },

    sendComment(content) {
      if (!this.$store.state.user.nickName) {
        this.personal();
      } else {
        this.$http.post("/api/source/comment", { source_id: this.source.id, content: content }).then(() => {
          this.query_comment_list();
          this.$message({
            message: this.$t('提交成功'),
            type: 'success'
          });
        })
      }
    },

    sendChidComment(content, target_id, parent_id) {
      console.log(content, target_id, parent_id);

      if (!this.$store.state.user.nickName) {
        this.personal();
      } else {
        this.$http.post("/api/source/comment", { source_id: this.source.id, parent_id: parent_id, target_id: target_id, content: content }).then(() => {
          this.query_comment_list();
          this.$message({
            message: this.$t('提交成功'),
            type: 'success'
          });
        })
      }
    },

    query_review_list() {
      this.$http.post("/api/review/list", { source_id: this.source.id }).then((list) => {
        this.reviewList = list;
      })
    },


    query_exam_list() {
      this.$http.post("/api/source/exam/list", { source_id: this.source.id }).then((list) => {
        this.examList = list;
      })
    },

    query_comment_list(page) {
      this.comment_page = page;
      this.$http.post("/api/comment/list", { source_id: this.source.id, page: this.comment_page }).then((res) => {
        this.comment_total = res.total;
        this.commentList = res.data;
      })
    },

    query_work_record_list(page) {
      this.activeName = "4"
      this.work_record_page = page;
      this.$http.post("/api/work/record/list", { source_id: this.source.id, flag: this.work_record_flag, page: this.work_record_page }).then((res) => {
        this.workRecordList = res.data;
        this.work_record_total = res.total;
      })
    },

    query_record_list(page) {
      this.recordList = [];
      this.record_page = page;
      this.$http.post("/api/record/list", { source_id: this.source.id, flag: this.record_flag, page: this.record_page }).then((res) => {
        let list = res.data;
        list.forEach(item => {
          if (item.content == null) {
            item.content = "";
            item.height = 0;
          }

          if (this.source.tag == 'WFD' && item.items) {
            item.items.forEach((item1) => {
              if (item1.content == null) {
                item1.content = "";
                item1.height = 0;
              }
              if (item1.content && this.source.tag == 'WFD') {
                let data = this.getWFDData(this.source.answer, item1.content);
                item1.contents = data;
              }
            })
          }

          if (item.content && this.source.tag == 'WFD') {
            let data = this.getWFDData(this.source.answer, item.content);
            item.contents = data;
          }
          this.recordList.push(item)
        });
        this.record_total = res.total;
      })
    },

    getWFDData(answer, content) {

      let str1 = answer.replace(/[,.]/g, "").replace(/\s+/g, ' ');
      let str2 = content.replace(/[,.]/g, "").replace(/\s+/g, ' ');

      let _arr1 = str1.split(" ");
      let _arr2 = str2.split(" ");


      let arr1 = [];
      _arr1.forEach((item, index) => {
        if (item.indexOf("^") == 0) {
          _arr1[index] = item.replace("^", "");
          arr1.push(item.replace("^", ""));
        } else {
          arr1.push(item.toLowerCase());
        }
      })
      let arr2 = str2.split(" ");

      let arr = [];
      let index = -1;
      let index2 = -1;

      let item1 = null;
      for (let index1 = 0; index1 < arr1.length; index1++) {
        item1 = arr1[index1];
        if (item1) {
          index = arr2.findIndex((val) => {
            return (item1.split("/").indexOf(val) != -1 || item1.split("/").indexOf(val.toLowerCase()) != -1);
          })

          if (index == -1) {
            arr.push({ word: _arr1[index1], class: "wrong1" });
            arr1[index1] = "";
          } else {
            for (let i = 0; i <= index; i++) {
              if (arr2[i]) {

                index2 = arr1.findIndex((val) => {
                  return (val.split("/").indexOf(arr2[i]) != -1 || val.split("/").indexOf(arr2[i].toLowerCase()) != -1);
                })
                if (index2 == -1) {
                  arr.push({ word: _arr2[i], class: "wrong2" });
                } else {
                  arr.push({ word: _arr2[i], class: "right" });
                  arr1[index2] = "";
                }
                arr2[i] = "";
              }
            }
          }
        }
      }
      for (let j = 0; j < arr2.length; j++) {
        if (arr2[j]) {
          arr.push({ word: arr2[j], class: "wrong2" });
        }
      }
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].class == "wrong1" && (i == 0 || arr[i - 1].class != 'wrong1')) {
          arr[i].word = "(" + arr[i].word;
        }
        if (arr[i].class == "wrong1" && (i == arr.length - 1 || arr[i + 1].class != 'wrong1')) {
          arr[i].word = arr[i].word + ")";
        }

        if (i == arr.length - 1 && content.endsWith(".")) {
          arr[i].word += "."
        }
      }
      return arr;
    },

    examedSubmit() {
      this.$refs.examForm.validate((valid) => {
        if (valid) {
          this.examForm["source_id"] = this.source.id;
          this.$http.post("/api/source/exam", this.examForm).then((count) => {
            this.source.exams_count = count;
            this.dialogVisible = false;
            this.$message({
              message: this.$t('提交成功，感谢您的分享') + '！',
              type: 'success'
            });
            this.query_exam_list();
          })
        }
      });
    },

    reDo() {
      this.timer.h = 0;
      this.timer.m = 0;
      this.timer.s = 0;
      this.activeName = "0";
      this.lastActiveName = "0"
      this.answer_flag = 0;
      this.submitText = "提交";
      this.$refs.holder.reDo(1);
      this.answerChange();
    },

    recorder_pause() {

    },

    recorder_stop() {
    },

    goBack() {
      if (this.no) {
        this.$router.back();
      } else if (this.tag) {
        if (this.tag == "prediction") {
          this.$router.back();
        } else if (this.tag.indexOf("star") == 0) {
          this.$router.back();
        } else {
          let type = this.$store.getters.getType(this.tag);
          this.$router.replace("/main/practise#" + type.sort);
        }
      } else {
        this.$router.back();
      }

    },

    setSource(source) {
      if (!source) source = this.list[this.source_index % this.pageSize];
      this.source = null
      this.$nextTick(() => {
        this.$bus.$emit('word-refresh')
        this.source = source
      })
    },



    choose(index) {
      if (this.isReseting) return
      this.source_index = (this.page - 1) * this.pageSize + index;
      this.setSource(this.list[index])
      this.drawer = false;
    },

    random_index(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },

    next() {
      this.lastActiveName = "0";
      this.source_index++;
      if (this.source_index == this.page * this.pageSize) {
        this.page++;
        this.source.id = 0;
        this.query();
      } else {
        let page = parseInt(this.source_index / this.pageSize) + 1;
        if (this.page != page) {
          this.page = page;
          this.source.id = 0;
          this.query();
        } else {
          this.setSource();
        }
      }
    },

    pre() {
      this.lastActiveName = "0";
      this.source_index--;
      if (this.source_index == (this.page - 1) * this.pageSize - 1) {
        this.page--;
        this.source.id = 0;
        this.query();
      } else {
        let page = parseInt(this.source_index / this.pageSize) + 1;
        if (this.page != page) {
          this.page = page;
          this.source.id = 0;
          this.query();
        } else {
          this.setSource();
        }
      }
    },

    query(page, flag) {
      this.loading0 = true;
      if (page) this.page = page;
      if (page == 1 && !flag) {
        this.source_index = 0;
      }
      let param = { page: this.page, tag: this.tag, type: this.type, no: this.no, prediction_tag: this.prediction_tag, favorite_tag: this.favorite_tag, filter: this.filterData };


      param["random"] = this.random ? 1 : 0;
      this.$http.post("/api/source/list", param).then((res) => {
        if (res.title) this.title = res.title;
        this.list = res.data;
        this.list.forEach((item) => {
          item.stars = item.favorite ? item.favorite.stars : 0;
        })
        this.allCount = res.all_count;
        this.recordCount = res.record_count;
        this.total = res.total;
        if (this.total > 0) {
          if (this.tag && !this.no) {
            localStorage.setItem(this.tag + "_sort1", this.sort1);
            localStorage.setItem(this.tag + "_last_param", JSON.stringify(param));
          }
        }
        if (this.source_index > 0 && this.source_index >= this.total) {
          this.resetParam();
          this.source_index = this.total - 1;
          this.page = parseInt(this.source_index / this.pageSize) + 1;
          this.loading0 = false;
          this.query();
        } else {
          if (!this.source || !this.source.id) {
            this.setSource(this.list[this.source_index % this.pageSize])
          }
          this.loading0 = false;
        }
      })
    }
  },
};
</script>

<style scoped>
.pte>>>.el-collapse-item__header {
  color: #999;
}

.word-static>>>.el-collapse-item__arrow {
  display: none;
}

.word-static>>>.el-collapse-item.is-disabled .el-collapse-item__header {
  color: #303133;
}

.word-static>>>.el-collapse-item__header {
  cursor: inherit;
}

.aside {
  position: absolute;
  left: 1040px;
  top: 20px;
}

.side-r>>>.el-collapse,
.side-r>>>.el-collapse-item__wrap,
.side-r>>>.el-collapse-item__header {
  border: none !important;
}

.side-r>>>.el-card__body {
  padding: 10px;
}

.main {
  margin: 0 10px;
}

.drag>>>.el-dialog__body {
  padding: 10px 15px !important;
}

.drawer>>>.el-tag--dark {
  border: none !important;
}

.on {
  color: #e6a13c;
  font-weight: bold;
}

.Speaking>>>.el-card__header {
  color: #fff;
  background: #de7d89;
}

.Speaking>>>.el-tag--primary {
  background: #de7d89 !important;
}



.Writing>>>.el-card__header {
  color: #fff;
  background: #796f85;
}


.Writing>>>.el-tag--primary {
  background: #796f85 !important;
}




.Reading>>>.el-tag--primary {
  background: #d5795d !important;
}


.Reading>>>.el-card__header {
  background: #d5795d;
  color: #fff;
}

.Listening>>>.el-card__header {
  background: #86a6c5;
  color: #fff;
}

.Listening>>>.el-tag--primary {
  background: #86a6c5 !important;
}

.pte {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  width: 1200px;
}

.work>>>.el-rate__icon {
  margin-top: -2px;
  font-size: 24px !important;
}

.drawer-btn {
  height: 60px;
  width: 60px;
  background-color: #E6A23C;
  border-radius: 50%;
  position: fixed;
  right: -30px;
  top: 50%;
  margin-top: -30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 8888;
  transition: right 0.15s ease-in-out;
}

.drawer {
  background-color: #fff;
  padding: 0 30px;
}

.drawer h3 {
  font-size: 30px;
}


.drawer>>>.el-input__inner {
  border-color: #e6a13c;
  border-radius: 20px;
}

.filter>>>.el-input__inner {
  color: #e6a13c;
}

.drawer>>>.el-menu--horizontal>.el-menu-item.is-active {
  color: #e6a13c;
}


.drawer-btn.open {
  right: calc(65% - 30px);
  transition: right 0.25s ease-in-out;
}


.drawer-btn.open i {
  margin-left: 0;
}

.drawer-btn i {
  font-size: 40px;
  color: #fff;
  font-weight: 500;
  margin-left: -20px;
}

.info-icon {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
}

.select {
  width: 120px;
}





.icon {
  font-size: 20px;
}

.timer {
  word-spacing: 4px;
  letter-spacing: 4px;
  font-weight: 800;
}






.header1 {
  padding: 0 28px;
  background-color: #ebc137;
  border-radius: 10px;
  color: #fff;
}

.Speaking1.active,
.Speaking1:hover {
  background: #de7d89;
  color: #fff;
}

.Writing1.active,
.Writing1:hover {
  background: #796f85;
  color: #fff;
}

.Reading1.active,
.Reading1:hover {
  background: #d5795d;
  color: #fff;
}

.Listening1.active,
.Listening1:hover {
  background: #86a6c5;
  color: #fff;
}
</style>
